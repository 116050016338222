import React from "react";
import "./puzzleBoard.css";
import { IPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { PuzzleBoardSpace, SpaceState } from "./puzzleBoardSpace";
import { extractWord } from "../../gameLogic/extractWord";
import { WordInProgress } from "./wordInProgress";
import { IObservable } from "../../observable/observable";
import { useObservableProperty } from "../../observable/useObservableProperty";
import { PointerStateManagerContext, Space } from "../../gameLogic/pointerStateManager";

export type PuzzleBoardProps = {
  puzzle: IPuzzleV1;
  onClear: () => void;
  onScoreWord: () => void;
  feedbackObservable: IObservable<string>;
};

export function PuzzleBoard(props: PuzzleBoardProps) {
  const pointerStateManager = React.useContext(PointerStateManagerContext);
  if (!pointerStateManager) {
    throw Error("A PointerStateManagerContext is required");
  }
  const selectedSpaceList = useObservableProperty(pointerStateManager.selectedSpaceList);
  /** Measured in number of 90 degree rotations clockwise */
  const [rotation, setRotation] = React.useState<number>(0);
  const rowCount = props.puzzle.board.length;
  const colCount = props.puzzle.board.length > 0 ? props.puzzle.board[0].length : 0;

  let puzzleRows = React.useMemo<React.JSX.Element[]>(() => {
    let puzzleRows: React.JSX.Element[] = [];
    let spaceState: SpaceState = "Rest";

    function addSquareToRow(puzzleRow: React.JSX.Element[], x: number, y: number) {
      if (
        selectedSpaceList.length > 0 &&
        selectedSpaceList[selectedSpaceList.length - 1].x === x &&
        selectedSpaceList[selectedSpaceList.length - 1].y === y
      ) {
        spaceState = "Tail";
      } else if (
        !!selectedSpaceList.find((candidate: Space) => candidate.x === x && candidate.y === y)
      ) {
        spaceState = "Included";
      } else {
        spaceState = "Rest";
      }
      const space = { x, y };
      puzzleRow.push(
        <PuzzleBoardSpace
          key={`square_${x}_${y}`}
          letter={props.puzzle.board[y][x]}
          state={spaceState}
          space={space}
        ></PuzzleBoardSpace>,
      );
    }

    switch (rotation) {
      case 0:
        {
          for (let y = 0; y < rowCount; y++) {
            const puzzleRow: React.JSX.Element[] = [];
            for (let x = 0; x < colCount; x++) {
              addSquareToRow(puzzleRow, x, y);
            }

            puzzleRows.push(
              <div className="puzzleBoardRow" key={`row${y}`}>
                {puzzleRow}
              </div>,
            );
          }
        }
        break;
      case 1:
        {
          for (let x = 0; x < rowCount; x++) {
            const puzzleRow: React.JSX.Element[] = [];
            for (let y = 3; y >= 0; y--) {
              addSquareToRow(puzzleRow, x, y);
            }

            puzzleRows.push(
              <div className="puzzleBoardRow" key={`row${x}`}>
                {puzzleRow}
              </div>,
            );
          }
        }
        break;
      case 2:
        {
          for (let y = 3; y >= 0; y--) {
            const puzzleRow: React.JSX.Element[] = [];
            for (let x = 3; x >= 0; x--) {
              addSquareToRow(puzzleRow, x, y);
            }

            puzzleRows.push(
              <div className="puzzleBoardRow" key={`row${y}`}>
                {puzzleRow}
              </div>,
            );
          }
        }
        break;
      case 3:
        {
          for (let x = 3; x >= 0; x--) {
            const puzzleRow: React.JSX.Element[] = [];
            for (let y = 0; y < rowCount; y++) {
              addSquareToRow(puzzleRow, x, y);
            }

            puzzleRows.push(
              <div className="puzzleBoardRow" key={`row${x}`}>
                {puzzleRow}
              </div>,
            );
          }
        }
        break;
    }

    return puzzleRows;
  }, [rotation, selectedSpaceList]);

  const onScoreWordClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
    props.onScoreWord();
    event.stopPropagation();
  };

  const onRotateClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setRotation((oldRotation: number) => (oldRotation + 1) % 4);
    event.stopPropagation();
  };

  return (
    <div className="puzzleBoardInnerContainer">
      <WordInProgress
        word={extractWord(props.puzzle, selectedSpaceList)}
        feedbackObservable={props.feedbackObservable}
      />
      <div className="puzzleRowsContainer">{puzzleRows}</div>
      <div className="buttonsBelowPuzzleContainer">
        <button className={`rotateButton`} onClick={onRotateClicked}>
          <div className="buttonText">Rotate</div>
        </button>
        <button
          disabled={selectedSpaceList.length === 0}
          className={`scoreWordButton${selectedSpaceList.length === 0 ? " disabled" : ""}`}
          onClick={selectedSpaceList.length > 0 ? onScoreWordClicked : undefined}
        >
          <div className="buttonText">Score Word</div>
        </button>
      </div>
    </div>
  );
}

export type SimpleDate = {
  /** NOTE: Not 0 indexed */
  year: number;

  /** NOTE: NOT 0 indexed like Date likes to use - May is 5 */
  month: number;

  /** NOTE: Not 0 indexed */
  day: number;
};

export function areSimpleDatesEqual(date1: SimpleDate, date2: SimpleDate): boolean {
  return date1.day === date2.day && date1.month === date2.month && date1.year === date2.year;
}

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function getDayName(date: SimpleDate): string {
  return dayNames[new Date(date.year, date.month - 1, date.day).getDay()];
}

export function getDateString(date: SimpleDate): string {
  return new Date(date.year, date.month - 1, date.day).toLocaleDateString();
}

export function compareSimpleDates(a: SimpleDate, b: SimpleDate): number {
  return (
    new Date(a.year, a.month - 1, a.day).getTime() - new Date(b.year, b.month - 1, b.day).getTime()
  );
}

import { isLegalMove } from "./isLegalMove";
import { Space } from "./pointerStateManager";

export function findWordInBoard(board: string[][], wordToFind: string): Space[] {
  return findWordInBoardInternal(board, wordToFind.toLowerCase(), []) || [];
}

function findWordInBoardInternal(
  board: string[][],
  wordToFind: string,
  selectedSpaces: Space[],
): Space[] | undefined {
  const rowCount = board.length;
  const colCount = board.length > 0 ? board[0].length : 0;

  const nextChar = wordToFind[selectedSpaces.length];

  for (let x = 0; x < colCount; x++) {
    for (let y = 0; y < rowCount; y++) {
      if (board[y][x].toLowerCase() === nextChar && isLegalMove({ x, y }, selectedSpaces, board)) {
        selectedSpaces.push({ x, y });

        if (selectedSpaces.length === wordToFind.length) {
          // We did it, return it out
          return selectedSpaces;
        }
        const result = findWordInBoardInternal(board, wordToFind, selectedSpaces);
        if (result) {
          // Found it, bubble it out
          return result;
        } else {
          // This was a dead end, remove that selected space and move on
          selectedSpaces.splice(selectedSpaces.length - 1, 1);
        }
      }
    }
  }

  return undefined;
}

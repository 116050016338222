import React from "react";
import "./home.css";
import { DataLayerContext } from "../dataLayer/DataLayer";
import { IPuzzleV1 } from "../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { PuzzleInList } from "./components/puzzleInList";
import { Header } from "./components/header";
import { HelpPanel } from "./components/helpPanel";

export function HomeScreen() {
  const dataLayer = React.useContext(DataLayerContext);
  const lastPuzzleRef = React.useRef<HTMLDivElement>(null);

  const listOfPuzzles: React.JSX.Element[] = [
    <div key="beginningOfList" className="puzzleInListLastPlaceholder"></div>,
  ];
  for (let index = 0; index < dataLayer.puzzles.length; index++) {
    const puzzle: IPuzzleV1 = dataLayer.puzzles[index];
    if (index === dataLayer.puzzles.length - 1) {
      listOfPuzzles.push(<PuzzleInList key={index} puzzle={puzzle} />);
    } else {
      listOfPuzzles.push(<PuzzleInList key={index} puzzle={puzzle} />);
    }
  }
  listOfPuzzles.push(
    <div key="endOfList" className="puzzleInListLastPlaceholder" ref={lastPuzzleRef}></div>,
  );

  React.useEffect(() => {
    lastPuzzleRef.current?.scrollIntoView({ behavior: "instant" });
  }, [lastPuzzleRef.current]);

  const onOpenPlayerStats = () => {
    dataLayer.currentScreen.value = "PlayerStats";
  };

  return (
    <div className="homeContainer">
      <Header />
      <div className="homePuzzleListContainer">{listOfPuzzles}</div>
      <div className="playerStatsContainer">
        <a href="#" onClick={onOpenPlayerStats}>
          Your Stats
        </a>
      </div>
      <div className="sendFeedbackContainer">
        <a href="mailto:beta@wirdergame.com?subject=Feedback">Send Feedback</a>
      </div>
      <HelpPanel />
    </div>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.puzzleContainer {
  display: flex;
  align-items: stretch;
  flex: 1;
  margin: 4px 10px 10px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/components/puzzleBoardContainer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,OAAO;EACP,0BAA0B;AAC5B","sourcesContent":[".puzzleContainer {\n  display: flex;\n  align-items: stretch;\n  flex: 1;\n  margin: 4px 10px 10px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

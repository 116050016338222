import React from "react";
import "./feedbackOverlay.css";
import { IObservable } from "../../observable/observable";

export type FeedbackOverlayProps = {
  feedbackObservable: IObservable<string>;
};

export function FeedbackOverlay(props: FeedbackOverlayProps) {
  const [feedbackList, setFeedbackList] = React.useState<string[]>([]);

  React.useEffect(() => {
    const unsubscribe = props.feedbackObservable.subscribe((newFeedback: string) => {
      setFeedbackList((oldList: string[]) => {
        const newFeedbackList = oldList.slice();
        newFeedbackList.push(newFeedback);
        return newFeedbackList;
      });
    });

    return () => {
      unsubscribe();
    };
  }, [props.feedbackObservable, setFeedbackList]);

  return (
    <>
      {feedbackList.map((feedback: string, index: number) => {
        return (
          <div key={index} className="feedbackOverlay">
            {feedback}
          </div>
        );
      })}
    </>
  );
}

import { IPuzzleV1 } from "../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { Space } from "./pointerStateManager";

export function extractWord(puzzle: IPuzzleV1, selectedSpaceList: Space[]): string {
  let word: string = "";
  for (const selectedSpace of selectedSpaceList) {
    word += puzzle.board[selectedSpace.y][selectedSpace.x];
  }
  return word.toLowerCase();
}

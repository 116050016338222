import React from "react";
import "./scorePanel.css";
import { IPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { useObservableProperty } from "../../observable/useObservableProperty";
import { IWordInPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IWordInPuzzleV1";

export type ScorePanelProps = {
  puzzle: IPuzzleV1;
  timeRemainingInSeconds: number;
};

export function ScorePanel(props: ScorePanelProps) {
  const regularPoints = useObservableProperty<number | undefined>(props.puzzle.regularPoints);
  let key = 0;
  const wordsFound = useObservableProperty<IWordInPuzzleV1[]>(props.puzzle.foundWords);
  let wordsFoundToDisplay = wordsFound
    .map((word: IWordInPuzzleV1) => {
      return `${word.word.toLowerCase()} `;
    })
    .reverse();
  return (
    <div className="scoreBoard">
      <div className="wordsFoundPanel">
        <div className="wordsFoundList">{wordsFoundToDisplay}</div>
      </div>
      <div className="pointsPanel">
        <table>
          <tbody>
            <tr>
              <td className="label">Time:</td>
              <td className="value">
                {Math.floor(props.timeRemainingInSeconds / 60)}:
                {pad(Math.floor(props.timeRemainingInSeconds % 60))}
              </td>
            </tr>
            <tr>
              <td className="label">Words:</td>
              <td className="value">{wordsFound.length}</td>
            </tr>
            <tr>
              <td className="label">Points:</td>
              <td className="value">{regularPoints?.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
        <div className="explanation">
          Rarity Bonuses
          <br /> Awarded tomorrow
        </div>
      </div>
    </div>
  );
}

function pad(value: number): string {
  if (value < 10) {
    return "0" + value.toString();
  } else {
    return value.toString();
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerContainer {
  display: flex;
  flex-basis: 55px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: rgb(162, 203, 250);
  border-bottom: 1px solid blue;
  filter: drop-shadow(gray 0px 4px 4px);
}

.headerContainer h1 {
  margin: 0px;
  padding: 0px;
}

.headerContainer .titleContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerContainer .backButtonContainer {
  position: absolute;
  height: 55px;
  width: 60px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerContainer .backButton {
  font-size: 20pt;
  font-weight: lighter;
  text-align: center;
  vertical-align: center;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}

.headerContainer .shareButtonContainer {
  position: absolute;
  height: 55px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerContainer .shareButtonContainer button {
 margin-right:8px;
}

.headerContainer .shareButtonContainer .buttonText {
  font-size: 12pt;
  margin: 8px;  
}`, "",{"version":3,"sources":["webpack://./src/screens/components/header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,oCAAoC;EACpC,6BAA6B;EAC7B,qCAAqC;AACvC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,kBAAkB;EAClB,sBAAsB;EACtB,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".headerContainer {\n  display: flex;\n  flex-basis: 55px;\n  flex-shrink: 0;\n  flex-grow: 0;\n  background-color: rgb(162, 203, 250);\n  border-bottom: 1px solid blue;\n  filter: drop-shadow(gray 0px 4px 4px);\n}\n\n.headerContainer h1 {\n  margin: 0px;\n  padding: 0px;\n}\n\n.headerContainer .titleContainer {\n  width: 100vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.headerContainer .backButtonContainer {\n  position: absolute;\n  height: 55px;\n  width: 60px;\n  left: 0px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.headerContainer .backButton {\n  font-size: 20pt;\n  font-weight: lighter;\n  text-align: center;\n  vertical-align: center;\n  padding: 10px 20px 10px 20px;\n  cursor: pointer;\n}\n\n.headerContainer .shareButtonContainer {\n  position: absolute;\n  height: 55px;\n  right: 0px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.headerContainer .shareButtonContainer button {\n margin-right:8px;\n}\n\n.headerContainer .shareButtonContainer .buttonText {\n  font-size: 12pt;\n  margin: 8px;  \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.puzzleReviewBoardRow {
  display: flex;
  flex-direction: row;
  height: 50px;
}

.puzzleReviewContainer {
}

.puzzleReviewBoardSpaceContainer {
  height: 50px;
  width: 50px;
  container-type: size;
}
.puzzleReviewOverlayCanvas {
  position: absolute;
  width: 200px;
  height: 200px;
  z-index: 1;
}
.puzzleReviewRowsContainer {
  height: 200px;
  width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/components/puzzleReviewBoard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;AACA;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,UAAU;AACZ;AACA;EACE,aAAa;EACb,YAAY;AACd","sourcesContent":[".puzzleReviewBoardRow {\n  display: flex;\n  flex-direction: row;\n  height: 50px;\n}\n\n.puzzleReviewContainer {\n}\n\n.puzzleReviewBoardSpaceContainer {\n  height: 50px;\n  width: 50px;\n  container-type: size;\n}\n.puzzleReviewOverlayCanvas {\n  position: absolute;\n  width: 200px;\n  height: 200px;\n  z-index: 1;\n}\n.puzzleReviewRowsContainer {\n  height: 200px;\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { useContext, useRef } from "react";
import { DataLayerContext, LoadOptions, LoadResult } from "../dataLayer/DataLayer";
import "./loadingScreen.css";
import { Header } from "./components/header";
import { IModalDialogConfig, ModalDialog } from "./components/modalDialog";
import { DictionaryContext } from "../dataLayer/Dictionary";

export function LoadingScreen() {
  const dataLayer = useContext(DataLayerContext);
  const dictionary = useContext(DictionaryContext);

  const [modalDialogConfig, setModalDialogConfig] = React.useState<
    IModalDialogConfig | undefined
  >();

  React.useEffect(() => {
    async function load(loadOptions?: LoadOptions) {
      const loadResult = await dataLayer.load(loadOptions);
      console.log("loadResult", loadResult);

      switch (loadResult) {
        case "AccountNotFoundAndNewAccountsNotAllowedRightNow":
        case "NewAccountsNotAllowedRightNow":
          {
            setModalDialogConfig({
              title: "You're Early!",
              message: (
                <div>
                  <p>Wirder is in private beta and isn't accepting new players just yet.</p>
                  <p>We hope you'll come back to play when it's ready!</p>
                  <p>
                    If you want to be notified when we are accepting new players, tap Notify Me
                    below.
                  </p>
                  <p>
                    Thanks!
                    <br />
                    The Wirder Team
                  </p>
                </div>
              ),
              leftButtonText: "Bye!",
              onLeftButtonClick: () => {
                window.location.href = "https://www.bing.com";
              },
              rightButtonText: "Notify Me",
              onRightButtonClick: () => {
                window.location.href = "mailto:beta@wirdergame.com?subject=Beta invite request";
              },
            });
          }
          break;
        case "Error":
          {
            setModalDialogConfig({
              title: "Error",
              message: (
                <div>
                  <p>We're sorry, there was an error when trying to contact the server.</p>
                  <p>Try again in a few moments, and please let us know about the problem!</p>
                </div>
              ),
              leftButtonText: "Try again",
              onLeftButtonClick: () => {
                window.location.reload();
              },
              rightButtonText: "Tell Us",
              onRightButtonClick: () => {
                window.location.href =
                  "mailto:beta@wirdergame.com?subject=I hit an error loading Wirder";
              },
            });
          }
          break;
        case "ConfirmUseNewAccountInsteadOfSavedOne":
          {
            setModalDialogConfig({
              title: "Switch Accounts?",
              message: (
                <div>
                  <p>
                    You're already logged in to one account on this device, but you opened Wirder
                    using a link to a different account.
                  </p>
                  <p>Do you want to use your saved account, or switch to the one from the link?</p>
                </div>
              ),
              leftButtonText: "Use Saved",
              onLeftButtonClick: () => {
                setModalDialogConfig(undefined);
                load("UseSavedAccount").catch((e) => {
                  console.error(e);
                });
              },
              rightButtonText: "Use Link",
              onRightButtonClick: () => {
                setModalDialogConfig(undefined);
                load("OverrideSavedAccountWithNewOne").catch((e) => {
                  console.error(e);
                });
              },
            });
          }
          break;
        case "Success":
          {
            dataLayer.currentScreen.value = "Home";
          }
          break;
      }
    }

    // Prewarm dictionary

    dictionary.isValidWord("CAT").catch((e) => {
      console.error(e);
    });

    // Start the load of the session
    load().catch((e) => {
      console.error(e);
    });
  }, [dataLayer, setModalDialogConfig]);

  return (
    <div className="loadingScreenContainer">
      <Header />
      <div className="loadingMessageContainer">
        <div className="loadingMessage">Loading...</div>
      </div>
      <ModalDialog dialogConfig={modalDialogConfig}></ModalDialog>
    </div>
  );
}

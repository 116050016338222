import React from "react";
import "../reviewPuzzleScreen.css";
import { IPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { IWordInPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IWordInPuzzleV1";

export interface IReviewPointsProps {
  puzzle: IPuzzleV1;
  totalScore: number | undefined;
  regularPoints: number | undefined;
  rarityPoints: number | undefined;
}

export function ReviewPoints(props: IReviewPointsProps) {
  return (
    <>
      <div className="majorSectionHeader">Points</div>
      <div className="majorSectionContainer">
        <table>
          <tbody>
            <tr>
              <td>Total:</td>
              <td>{props.puzzle.isToday ? "" : props.totalScore?.toLocaleString()}</td>
            </tr>
            <tr>
              <td>Regular:</td>
              <td>{props.regularPoints?.toLocaleString()}</td>
            </tr>
            <tr>
              <td>Rarity:</td>
              <td>{props.puzzle.isToday ? "" : props.rarityPoints?.toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scoreBoard {
  display: flex;
  margin: 0px 10px 0px 10px;
}

.pointsPanel {
  background-color: white;
  filter: drop-shadow(gray 1px 3px 5px);
  flex-basis: 0 0 170px;
  margin: 16px 8px 8px 8px;
  font-size: 12pt;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.pointsPanel table {
  flex-grow: 1;
  margin-bottom: 0px;
}

.wordsFoundPanel {
  padding: 2px;
  margin-top: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.wordsFoundList {
  flex-basis: 0px;
  flex-grow: 1;
  overflow-y: auto;
}

.explanation {
  margin: 0px 4px 8px 4px;
  padding: 2px;
  font-size: 10pt;
  white-space: nowrap;
  overflow: hidden;
}

.pointsPanel tr td:first-child {
  font-weight: normal;
}

.pointsPanel tr td:nth-child(2) {
  font-weight: bold;
  font-size: 13pt;
}
`, "",{"version":3,"sources":["webpack://./src/screens/components/scorePanel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,qCAAqC;EACrC,qBAAqB;EACrB,wBAAwB;EACxB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,OAAO;EACP,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".scoreBoard {\n  display: flex;\n  margin: 0px 10px 0px 10px;\n}\n\n.pointsPanel {\n  background-color: white;\n  filter: drop-shadow(gray 1px 3px 5px);\n  flex-basis: 0 0 170px;\n  margin: 16px 8px 8px 8px;\n  font-size: 12pt;\n  display: flex;\n  flex-direction: column;\n  align-items: baseline;\n}\n\n.pointsPanel table {\n  flex-grow: 1;\n  margin-bottom: 0px;\n}\n\n.wordsFoundPanel {\n  padding: 2px;\n  margin-top: 8px;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n}\n\n.wordsFoundList {\n  flex-basis: 0px;\n  flex-grow: 1;\n  overflow-y: auto;\n}\n\n.explanation {\n  margin: 0px 4px 8px 4px;\n  padding: 2px;\n  font-size: 10pt;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.pointsPanel tr td:first-child {\n  font-weight: normal;\n}\n\n.pointsPanel tr td:nth-child(2) {\n  font-weight: bold;\n  font-size: 13pt;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { useState, useEffect } from "react";
import { IObservableProperty } from "./observableProperty";

export function useObservableProperty<T>(observableProperty: IObservableProperty<T>) {
  const [value, setValue] = useState<T>(observableProperty.value);
  useEffect(() => {
    function handleNewValue(newValue: T) {
      setValue(newValue);
    }
    const unsubscribe = observableProperty.subscribe(handleNewValue, {
      notifyWithCurrentValue: true,
    });
    return unsubscribe;
  }, [observableProperty]);

  return value;
}

import React, { useContext } from "react";
import "../home.css";
import type { PuzzleInListProps } from "./puzzleInList";
import { DataLayerContext } from "../../dataLayer/DataLayer";
import { getDayName } from "../../dataLayer/SimpleDate";
import { useObservableProperty } from "../../observable/useObservableProperty";

export function PuzzleInListToday(props: PuzzleInListProps) {
  const dataLayer = useContext(DataLayerContext);
  const totalScore = useObservableProperty(props.puzzle.totalScore);

  const onClick = () => {
    dataLayer.currentPuzzle.value = props.puzzle;
    dataLayer.currentScreen.value = "Puzzle";
  };
  return (
    <div className="puzzleInListContainer clickable" onClick={onClick}>
      <div className="puzzleInListDayOfWeek">{getDayName(props.puzzle.date)}</div>
      <div className="puzzleInListCardContents">
        <div className="playNowButtonContainer">
          Play Now!
          {totalScore > 0 ? (
            <div className="puzzleInListScoreSoFar">
              <div>Score so far: {totalScore.toLocaleString()}</div>
              <div>Be sure to finish before the end of the day!</div>
            </div>
          ) : (
            <> </>
          )}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "../home.css";
import { IPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { PuzzleInListToday } from "./puzzleInListToday";
import { PuzzleInListPlayed } from "./puzzleInListPlayed";
import { PuzzleInListSkipped } from "./puzzleInListSkipped";

export type PuzzleInListProps = {
  puzzle: IPuzzleV1;
};

export function PuzzleInList(props: PuzzleInListProps) {
  if (props.puzzle.isToday) {
    if (props.puzzle.played) {
      return <PuzzleInListPlayed puzzle={props.puzzle} />;
    } else {
      return <PuzzleInListToday puzzle={props.puzzle} />;
    }
  } else {
    if (props.puzzle.played) {
      return <PuzzleInListPlayed puzzle={props.puzzle} />;
    } else {
      return <PuzzleInListSkipped puzzle={props.puzzle} />;
    }
  }
}

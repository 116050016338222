import {
  IObservableProperty,
  IReadOnlyObservableProperty,
  ObservableProperty,
} from "../../observable/observableProperty";
import { IPuzzleV1 } from "./interfaces/classes/IPuzzleV1";
import { SimpleDate, areSimpleDatesEqual } from "../SimpleDate";
import { getSimpleDateInSeattle } from "../getSimpleDateInSeattle";
import { IWordInPuzzleDataV1 } from "./interfaces/data/IWordInPuzzleDataV1";
import { IScoringRubricV1 } from "./interfaces/data/IScoringRubricV1";
import { IWordInPuzzleV1, RarityStatus } from "./interfaces/classes/IWordInPuzzleV1";
import { WordInPuzzleV1 } from "./WordInPuzzleV1";
import { IPuzzleRemoteDataV1 } from "./interfaces/data/IPuzzleRemoteDataV1";

export class PuzzleV1 implements IPuzzleV1 {
  private _data: IPuzzleRemoteDataV1;
  public readonly _foundWords: IObservableProperty<IWordInPuzzleV1[]>;
  public readonly _allWords: IObservableProperty<IWordInPuzzleV1[]>;
  public readonly _totalScore: IObservableProperty<number>;
  public readonly _regularPoints: IObservableProperty<number | undefined>;
  public readonly _rarityPoints: IObservableProperty<number | undefined>;

  public get timeElapsedInSeconds(): number {
    return this._data.timeElapsedInSeconds || 0;
  }

  public set timeElapsedInSeconds(newValue: number) {
    this._data.timeElapsedInSeconds = Math.min(this._data.timeLimitInSeconds, newValue);
  }

  public get timeLimitInSeconds(): number {
    return this._data.timeLimitInSeconds;
  }

  public get totalPlays(): number | undefined {
    return this._data.solution.totalSubmissions;
  }

  public get scoringRubric(): IScoringRubricV1 {
    return this._data.scoringRubric;
  }

  public get date(): SimpleDate {
    return this._data.date;
  }

  public get board(): string[][] {
    return this._data.board;
  }

  public get played(): boolean {
    return this._data.played;
  }

  public set played(newValue: boolean) {
    this._data.played = newValue;
  }

  public get foundWords(): IReadOnlyObservableProperty<IWordInPuzzleV1[]> {
    return this._foundWords;
  }
  public get allWords(): IReadOnlyObservableProperty<IWordInPuzzleV1[]> {
    return this._allWords;
  }
  public get totalScore(): IReadOnlyObservableProperty<number> {
    return this._totalScore;
  }
  public get regularPoints(): IReadOnlyObservableProperty<number | undefined> {
    return this._regularPoints;
  }
  public get rarityPoints(): IReadOnlyObservableProperty<number | undefined> {
    return this._rarityPoints;
  }

  public get isToday(): boolean {
    return areSimpleDatesEqual(getSimpleDateInSeattle(), this._data.date);
  }

  public get isPlayable(): boolean {
    return !this._data.played && this.isToday;
  }

  public constructor(data: IPuzzleRemoteDataV1) {
    this._data = data;

    this._foundWords = new ObservableProperty<IWordInPuzzleV1[]>([]);
    this._allWords = new ObservableProperty<IWordInPuzzleV1[]>(
      data.solution.wordsInPuzzle.map((wordInPuzzleData: IWordInPuzzleDataV1) => {
        const isFound: boolean =
          data.wordsFound.findIndex(
            (candidate: string) => wordInPuzzleData.word.toUpperCase() === candidate.toUpperCase(),
          ) !== -1;

        const wordInPuzzle: IWordInPuzzleV1 = new WordInPuzzleV1(
          wordInPuzzleData.word,
          this._getRegularPointsForWord(wordInPuzzleData.word),
          this._getRarityStatusForWord(wordInPuzzleData),
          wordInPuzzleData.timesFound,
          isFound,
        );

        // If the user has found this word, also add it to this._foundWords
        if (isFound) {
          this._foundWords.value.push(wordInPuzzle);
        }

        return wordInPuzzle;
      }),
    );

    this._totalScore = new ObservableProperty<number>(data.totalPoints || 0);
    this._regularPoints = new ObservableProperty<number | undefined>(data.regularPoints);
    this._rarityPoints = new ObservableProperty<number | undefined>(data.rarityPoints);
    this.calculateRegularPoints();
  }

  public addFoundWord(word: string): number {
    const wordInPuzzle = this._allWords.value.find(
      (candidate: IWordInPuzzleV1) => candidate.word.toUpperCase() === word.toUpperCase(),
    );

    if (wordInPuzzle) {
      if (wordInPuzzle.isFound) {
        console.warn("Tried to mark a word found that was already found!");
        return 0;
      }

      // Update the word now that it was found
      wordInPuzzle.isFound = true;
      wordInPuzzle.timesFound = (wordInPuzzle.timesFound || 0) + 1;

      // Add the word to the foundWords list, triggering a change notification
      const newFoundWordList = this._foundWords.value.slice();
      newFoundWordList.push(wordInPuzzle);
      this._foundWords.value = newFoundWordList;

      // Since we updated the word in the allWords list, trigger a change notification for it too for completeness
      this._allWords.value = this._allWords.value.slice();

      this.calculateRegularPoints();
      return wordInPuzzle.regularPoints;
    } else {
      console.error("Could not find the word the user just found in the solution for the puzzle!");
      return 0;
    }
  }

  public serialize(): IPuzzleRemoteDataV1 {
    return {
      date: this._data.date,
      board: this._data.board,
      played: this.played,
      wordsFound: this.foundWords.value.map((wordInPuzzle: IWordInPuzzleV1) =>
        wordInPuzzle.serialize(),
      ),
      scoringRubric: this._data.scoringRubric,
      solution: this._data.solution,
      timeLimitInSeconds: this._data.timeLimitInSeconds,
      timeElapsedInSeconds: this._data.timeElapsedInSeconds,
      totalPoints: this._data.totalPoints,
      regularPoints: this._data.regularPoints,
      rarityPoints: this._data.rarityPoints,
    };
  }

  private calculateRegularPoints(): void {
    let regularPoints = 0;
    if (this.foundWords.value) {
      this.foundWords.value.forEach((foundWord: IWordInPuzzleV1) => {
        regularPoints += foundWord.regularPoints;
      });
    }
    this._regularPoints.value = regularPoints;
  }

  private _getRegularPointsForWord(word: string): number {
    if (word.length < this._data.scoringRubric.wordLengthPoints.length) {
      return this._data.scoringRubric.wordLengthPoints[word.length - 1];
    }
    return this._data.scoringRubric.wordLengthPoints[
      this._data.scoringRubric.wordLengthPoints.length - 1
    ];
  }

  // TODO: start storing rarity status in the solution list server side then just read it on the client

  private _getRarityStatusForWord(wordInPuzzleData: IWordInPuzzleDataV1): RarityStatus | undefined {
    // If totalSubmissions are undefined (today's puzzle), or 0, RarityStatus is undefined
    if (!this._data.solution.totalSubmissions) {
      return undefined;
    }

    // If it hasn't been found at all, this will be undefined
    if (wordInPuzzleData.timesFound === undefined) {
      return undefined;
    }

    const percentFound = (wordInPuzzleData.timesFound * 100) / this._data.solution.totalSubmissions;

    if (
      wordInPuzzleData.timesFound === 1 ||
      percentFound <= this._data.scoringRubric.goldCriteria.maxPercent
    ) {
      return {
        rarityLevel: "Gold",
        bonus: this._data.scoringRubric.goldCriteria.bonus,
      };
    }
    if (
      wordInPuzzleData.timesFound === 2 ||
      percentFound <= this._data.scoringRubric.silverCriteria.maxPercent
    ) {
      return {
        rarityLevel: "Silver",
        bonus: this._data.scoringRubric.silverCriteria.bonus,
      };
    }
    if (
      wordInPuzzleData.timesFound === 3 ||
      percentFound <= this._data.scoringRubric.bronzeCriteria.maxPercent
    ) {
      return {
        rarityLevel: "Bronze",
        bonus: this._data.scoringRubric.bronzeCriteria.bonus,
      };
    }

    return undefined;
  }
}

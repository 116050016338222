import { createContext } from "react";

// TODO: modify the dictionary to return words shorter than MIN_WORD_LENGTH letters
const MIN_WORD_LENGTH = 3;

export class Dictionary {
  private _dictionaryPromise: Promise<Map<string, string[]>> | undefined;

  public async isValidWord(candidateWord: string): Promise<boolean> {
    if (this._dictionaryPromise === undefined) {
      this._dictionaryPromise = this.loadDictionary();
    }

    const dictionary = await this._dictionaryPromise;

    if (candidateWord.length < MIN_WORD_LENGTH) {
      return false;
    }

    const wordListStartingWithThatLetter = dictionary.get(candidateWord[0]);
    if (!wordListStartingWithThatLetter) {
      return false;
    }
    return wordListStartingWithThatLetter.indexOf(candidateWord.toLowerCase()) !== -1;
  }

  private async loadDictionary(): Promise<Map<string, string[]>> {
    console.log("Loading dictionary...");
    const rawWordList = (await import(/* webpackChunkName: "dictionary" */ "../../data/words.txt"))
      .default;
    const dictionary = new Map<string, string[]>();
    const rowDelimiter = rawWordList.indexOf("\r\n") !== -1 ? "\r\n" : "\n";

    // Assumes there are 2 rows to skip at the top of the file
    const rawArray = rawWordList.split(rowDelimiter).slice(2);

    // Assumes the word list is alphabetized
    let previousLetter: string = " ";
    let previousArray: string[] = [];
    for (const word of rawArray) {
      if (word) {
        if (word[0] !== previousLetter) {
          // Write out previous
          if (previousLetter !== " ") {
            dictionary.set(previousLetter, previousArray);
            previousArray = [];
          }
        }
        previousLetter = word[0];
        previousArray.push(word);
      }
    }
    dictionary.set(previousLetter, previousArray);
    previousArray = [];

    console.log("Dictionary loaded.");
    return dictionary;
  }
}

export const DictionaryContext = createContext<Dictionary>(new Dictionary());

import React, { useContext } from "react";
import "../home.css";
import type { PuzzleInListProps } from "./puzzleInList";
import { DataLayerContext } from "../../dataLayer/DataLayer";
import { getDateString, getDayName } from "../../dataLayer/SimpleDate";
import { useObservableProperty } from "../../observable/useObservableProperty";
import { IWordInPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IWordInPuzzleV1";

export function PuzzleInListPlayed(props: PuzzleInListProps) {
  const dataLayer = useContext(DataLayerContext);
  const totalScore = useObservableProperty<number>(props.puzzle.totalScore);
  const regularPoints = useObservableProperty<number | undefined>(props.puzzle.regularPoints);
  const rarityPoints = useObservableProperty<number | undefined>(props.puzzle.rarityPoints);
  const wordsFoundList = useObservableProperty<IWordInPuzzleV1[]>(props.puzzle.foundWords);
  const [rareWordsFound, setRareWordsFound] = React.useState<number>(0);

  React.useEffect(() => {
    let count: number = 0;
    wordsFoundList.forEach((value: IWordInPuzzleV1) => {
      if (value.rarityPoints) {
        count++;
      }
    });
    setRareWordsFound(count);
  }, [wordsFoundList]);

  const onClick = () => {
    dataLayer.currentPuzzle.value = props.puzzle;
    dataLayer.currentScreen.value = "ReviewPuzzle";
  };

  return (
    <div className="puzzleInListContainer clickable" onClick={onClick}>
      <div className="puzzleInListDayOfWeek">{getDayName(props.puzzle.date)}</div>
      <div className="puzzleInListDate">{getDateString(props.puzzle.date)}</div>
      <div className="puzzleInListCardContents">
        <div className="centeredContainer">
          <div className="totalScore">
            {props.puzzle.isToday ? "Submitted" : `${totalScore} Points`}
          </div>
          <div className="statContainer">
            <div className="statLabel">Words Found:</div>
            <div className="statValue">
              {wordsFoundList.length} ({regularPoints?.toLocaleString()} pts.)
            </div>
            <div className="statLabel">Rare Words:</div>
            <div className="statValue">
              {props.puzzle.isToday
                ? "Check tomorrow!"
                : `${rareWordsFound} (${rarityPoints?.toLocaleString()} pts.)`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { v4 as uuidv4 } from "uuid";
import { createContext } from "react";
import { type IObservableProperty, ObservableProperty } from "../observable/observableProperty";
import { ISessionData } from "./v1/interfaces/data/ISessionData";
import { ISessionDataV1 } from "./v1/interfaces/data/ISessionDataV1";
import { SessionV1 } from "./v1/SessionV1";
import { ISessionV1 } from "./v1/interfaces/classes/ISessionV1";
import { IPuzzleV1 } from "./v1/interfaces/classes/IPuzzleV1";
import { areSimpleDatesEqual, compareSimpleDates, SimpleDate } from "./SimpleDate";
import { getSimpleDateInSeattle } from "./getSimpleDateInSeattle";
import { IPuzzleRemoteDataV1 } from "./v1/interfaces/data/IPuzzleRemoteDataV1";
import { IWordInPuzzleV1 } from "./v1/interfaces/classes/IWordInPuzzleV1";
import { nullToUndefined } from "../helpers/nullToUndefined";
import { IPlayerStats } from "./v1/interfaces/data/IPlayerStats";

export type Screen =
  | "Loading"
  | "Home"
  | "Puzzle"
  | "ReviewPuzzle"
  | "SubmittingSolution"
  | "PlayerStats";
const LOCAL_DATA_KEY = "WirderLocalData";
const OFFLINE_MODE_KEY = "WirderOfflineMode";
export type LoadOptions = "OverrideSavedAccountWithNewOne" | "UseSavedAccount";
export type LoadResult =
  | "Success"
  | "NewAccountsNotAllowedRightNow"
  | "AccountNotFoundAndNewAccountsNotAllowedRightNow"
  | "Error"
  | "ConfirmUseNewAccountInsteadOfSavedOne";
type ServerResponse = "OK" | "Error" | "NoCallNeeded" | "GuidNotRecognized";

interface GetPuzzlesResponse {
  result: "Success" | "GuidNotFound" | "Error";
  puzzles: IPuzzleRemoteDataV1[];
}

interface GetAllPuzzlesResponse {
  result: "Success" | "GuidNotFound" | "Error";
  puzzles: IPuzzleRemoteDataV1[];
}

export type SubmitSolutionResult =
  | "Success"
  | "GuidNotFound"
  | "InvalidDate"
  | "SolutionAlreadySubmitted"
  | "Error";
interface SubmitSolutionResponse {
  result: SubmitSolutionResult;
  regular_points: number | undefined;
}

export class DataLayer {
  private _session: ISessionV1 | undefined;

  public get puzzles(): IPuzzleV1[] {
    return this._session?.puzzles || [];
  }

  public readonly currentPuzzle: IObservableProperty<IPuzzleV1 | undefined> =
    new ObservableProperty<IPuzzleV1 | undefined>(undefined);

  public readonly currentScreen: IObservableProperty<Screen> = new ObservableProperty<Screen>(
    "Loading",
  );

  public async load(options?: LoadOptions): Promise<LoadResult> {
    console.log("load options", options);

    const userGuidFromQueryString = this._getUserGuidFromQueryString();
    console.log("userGuidFromQueryString", userGuidFromQueryString);
    const savedSessionData = this._loadLocalSessionData();
    console.log("savedSession.userGuid", savedSessionData?.userGuid);

    let sessionData: ISessionDataV1;

    if (!savedSessionData) {
      if (userGuidFromQueryString) {
        console.log("No savedSessionData, but did find a userGuidFromQueryString");
        sessionData = this._createNewSessionData(userGuidFromQueryString);
      } else {
        console.log("No savedSessionData, no userGuidFromQueryString");
        // TODO for open beta: OOBE flow where you can create new account or supply email for existing one
        return "NewAccountsNotAllowedRightNow";
      }
    } else {
      // We do have savedSessionData
      if (userGuidFromQueryString && userGuidFromQueryString !== savedSessionData.userGuid) {
        console.log(
          "Both savedSessionData and userGuidFromQueryString found, but guids don't match",
        );
        switch (options) {
          case "OverrideSavedAccountWithNewOne":
            {
              if (userGuidFromQueryString.toLowerCase() === "reset") {
                localStorage.removeItem(LOCAL_DATA_KEY);
                console.log("Resetting local account");
                // TODO for open beta: OOBE flow where you can create new account or supply email for existing one
                return "NewAccountsNotAllowedRightNow";
              } else {
                console.log("Using guid from query string");
                sessionData = this._createNewSessionData(userGuidFromQueryString);
              }
            }
            break;
          case "UseSavedAccount":
            {
              console.log("Using saved session");
              sessionData = savedSessionData;
            }
            break;
          default: {
            console.log("Asking user which to use");
            return "ConfirmUseNewAccountInsteadOfSavedOne";
          }
        }
      } else if (userGuidFromQueryString && userGuidFromQueryString === savedSessionData.userGuid) {
        console.log("Both savedSessionData and userGuidFromQueryString found and guids match");
        sessionData = savedSessionData;
      } else {
        console.log("Found savedSession but no query string guid, using the savedSession");
        // Use the savedSessionData - we either didn't have a userGuidFromQueryString or it matched the savedSessionData
        sessionData = savedSessionData;
      }
    }

    console.log("using userGuid", sessionData.userGuid);

    let serverResponse: ServerResponse;
    if (sessionData.puzzles.length === 0) {
      serverResponse = await this.askServerForAllOurPuzzlesPlusToday(sessionData);
    } else {
      serverResponse = await this.updateDataFromServerIfNeeded(sessionData);
    }

    switch (serverResponse) {
      case "Error": {
        return "Error";
      }
      case "GuidNotRecognized": {
        return "AccountNotFoundAndNewAccountsNotAllowedRightNow";
      }
    }

    sessionData.puzzles.sort((a: IPuzzleRemoteDataV1, b: IPuzzleRemoteDataV1) =>
      compareSimpleDates(a.date, b.date),
    );
    this._session = new SessionV1(sessionData);
    this._saveLocalSessionData(this._session);

    this._updateAddressBarToRemoveUserGuid();
    return "Success";
  }

  public save(): void {
    if (this._session === undefined) {
      console.error("Tried to save, but had no session to save");
      return;
    }
    this._saveLocalSessionData(this._session);
  }

  public async submitPuzzle(): Promise<SubmitSolutionResult> {
    if (localStorage.getItem(OFFLINE_MODE_KEY) === "1") {
      console.error("Asked to submit a puzzle, but in offline mode");
      return "Error";
    }
    if (this.currentPuzzle.value === undefined) {
      console.error("Asked to submit a puzzle, but there is no current puzzle");
      return "Error";
    }

    this.save();
    const response = await this._submitPuzzleToServer(this.currentPuzzle.value);

    if (response.result == "Success") {
      this.currentPuzzle.value.played = true;
      this.currentPuzzle.value.regularPoints.value = response.regular_points;
      this.save();
    }

    return response.result;
  }

  public async getPlayerStats(): Promise<IPlayerStats | undefined> {
    if (this._session === undefined) {
      console.error("Tried to get player stats, but had no active session");
      return;
    }

    console.log("Asking the server for the player's stats");

    const url = `${this._getHostName()}/getPlayerStats?user_guid=${this._session.userGuid}`;
    const response = await fetch(url);
    if (!response.ok) {
      console.error("Call to getPlayerStats failed: ", await response.text());
      return;
    }

    const text = await response.text();
    return JSON.parse(text, nullToUndefined);
  }

  public findPuzzleByDate(date: SimpleDate): IPuzzleV1 | undefined {
    return this._session?.puzzles.find((puzzle: IPuzzleV1) =>
      areSimpleDatesEqual(puzzle.date, date),
    );
  }

  private async _submitPuzzleToServer(puzzle: IPuzzleV1): Promise<SubmitSolutionResponse> {
    if (this._session === undefined) {
      console.error("Tried to submit a solution, but had no active session");
      return { result: "Error", regular_points: undefined };
    }

    try {
      const wordsFound: string[] = puzzle.foundWords.value.map((wordFound: IWordInPuzzleV1) =>
        wordFound.word.toUpperCase(),
      );
      const url = `${this._getHostName()}/submitSolution?user_guid=${
        this._session.userGuid
      }&date=${JSON.stringify(puzzle.date)}`;

      const request = new Request(url, {
        method: "POST",
        body: JSON.stringify(wordsFound),
        headers: new Headers({ "content-type": "application/json" }),
      });
      const response = await fetch(request);

      if (!response.ok) {
        console.error("Call to submitSolution failed: ", await response.text());
        return { result: "Error", regular_points: undefined };
      }

      const text = await response.text();
      const submitSolutionResponse: SubmitSolutionResponse = JSON.parse(text, nullToUndefined);

      return submitSolutionResponse;
    } catch (e: unknown) {
      console.error("Error submitting puzzle:", e);
      return { result: "Error", regular_points: undefined };
    }
  }

  private async askServerForAllOurPuzzlesPlusToday(
    session: ISessionDataV1,
  ): Promise<ServerResponse> {
    // Request data from the server
    console.log("Asking the server for all of our puzzles, plus today's");

    const url = `${this._getHostName()}/getAllPuzzles?user_guid=${session.userGuid}`;
    const response = await fetch(url);
    if (!response.ok) {
      console.error("Call to getAllPuzzles failed: ", await response.text());
      return "Error";
    }

    const text = await response.text();
    const remotePuzzlesResponse: GetAllPuzzlesResponse = JSON.parse(text, nullToUndefined);
    if (remotePuzzlesResponse.result === "GuidNotFound") {
      return "GuidNotRecognized";
    } else if (remotePuzzlesResponse.result === "Error") {
      return "Error";
    }

    this._mergeRemotePuzzlesIntoSession(session, remotePuzzlesResponse.puzzles);

    return "OK";
  }

  private async updateDataFromServerIfNeeded(session: ISessionDataV1): Promise<ServerResponse> {
    // Build up list of dates we want updates from
    const todayInSeattle: SimpleDate = getSimpleDateInSeattle();
    let todaysPuzzle: IPuzzleRemoteDataV1 | undefined = undefined;
    const datesToFetch: SimpleDate[] = [];
    for (const puzzle of session.puzzles) {
      if (areSimpleDatesEqual(puzzle.date, todayInSeattle)) {
        todaysPuzzle = puzzle;
      } else {
        // We now scrub nulls and convert to undefined, but before that was done, some totalSubmissions were saved as null
        if (
          puzzle.solution.totalSubmissions === undefined ||
          puzzle.solution.totalSubmissions === null
        ) {
          datesToFetch.push(puzzle.date);
        }
      }
    }

    // If we don't have today's puzzle in the session yet, or we do, but it's unplayed, fetch it
    // We need to fetch it if it's unplayed in case it was played on another device
    if (!todaysPuzzle || !todaysPuzzle.played) {
      datesToFetch.push(todayInSeattle);
    }

    if (datesToFetch.length === 0) {
      console.log("No dates to fetch");
      return "NoCallNeeded";
    }

    // Request data from the server
    console.log("Asking the server for these dates: ", JSON.stringify(datesToFetch));
    const remotePuzzlesResponse = await this._requestPuzzlesFromServer(session, datesToFetch);

    if (remotePuzzlesResponse.result === "GuidNotFound") {
      return "GuidNotRecognized";
    } else if (remotePuzzlesResponse.result === "Error") {
      return "Error";
    }

    this._mergeRemotePuzzlesIntoSession(session, remotePuzzlesResponse.puzzles);

    return "OK";
  }

  private _mergeRemotePuzzlesIntoSession(
    session: ISessionDataV1,
    remotePuzzles: IPuzzleRemoteDataV1[],
  ): void {
    // Update our local state
    for (const remotePuzzle of remotePuzzles) {
      const existingPuzzleIndex = session.puzzles.findIndex((candidate: IPuzzleRemoteDataV1) =>
        areSimpleDatesEqual(candidate.date, remotePuzzle.date),
      );
      if (existingPuzzleIndex === -1) {
        // For new puzzles, add them to our local puzzle list
        session.puzzles.push({ ...remotePuzzle, timeElapsedInSeconds: 0 }); // TODO: when we start syncing timeElapsedInSeconds, don't hardcode it here
      } else {
        // For existing puzzles overwrite with the server data
        const timeElapsedInSeconds = session.puzzles[existingPuzzleIndex].timeElapsedInSeconds;
        const sessionWordsFound = session.puzzles[existingPuzzleIndex].wordsFound;
        const mergedWordsFound = [...sessionWordsFound, ...remotePuzzle.wordsFound];
        const mergedUniqueWordsFound = mergedWordsFound.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
        session.puzzles[existingPuzzleIndex] = {
          ...remotePuzzle,
          wordsFound: mergedUniqueWordsFound,
          timeElapsedInSeconds, // TODO: once we start syncing timeElapsed, don't stomp it here
        };
      }
    }

    // Sort
    session.puzzles.sort((a: IPuzzleRemoteDataV1, b: IPuzzleRemoteDataV1) =>
      compareSimpleDates(a.date, b.date),
    );
  }

  private async _requestPuzzlesFromServer(
    session: ISessionDataV1,
    datesToFetch: SimpleDate[],
  ): Promise<GetPuzzlesResponse> {
    const url = `${this._getHostName()}/getPuzzles?user_guid=${
      session.userGuid
    }&dates=${JSON.stringify(datesToFetch)}`;
    const response = await fetch(url);
    if (response.ok) {
      const text = await response.text();
      return JSON.parse(text, nullToUndefined);
    } else {
      console.error("Call to getPuzzles failed: ", await response.text());
      return {
        result: "Error",
        puzzles: [],
      };
    }
  }

  private _getHostName(): string {
    return localStorage["WirderUseLocalhostApi"] === "1"
      ? "http://localhost:5071"
      : "https://www.wirdergame.com/api";
  }

  private _saveLocalSessionData(session: ISessionV1): void {
    console.log("Saving session data to local storage");
    localStorage.setItem(LOCAL_DATA_KEY, JSON.stringify(session.serialize()));
  }

  private _loadLocalSessionData(): ISessionDataV1 | undefined {
    const serializedData = localStorage.getItem(LOCAL_DATA_KEY);
    if (serializedData !== null) {
      const dataObject = JSON.parse(serializedData) as ISessionData;
      switch (dataObject.version) {
        case "1": {
          console.log("Loading session data from local storage");
          return dataObject as ISessionDataV1;
        }
      }
    }

    return undefined;
  }

  private _createNewSessionData(userGuid?: string): ISessionDataV1 {
    if (userGuid) {
      console.log("Creating new session data with userGuid", userGuid);
    } else {
      console.log("Creating new session data with new userGuid");
    }
    return {
      version: "1",
      userGuid: userGuid || uuidv4(),
      puzzles: [],
    };
  }

  private _getUserGuidFromQueryString(): string | undefined {
    let userGuid = new URL(document.location.toString()).searchParams.get("user");
    return userGuid || undefined;
  }

  private _updateAddressBarToRemoveUserGuid(): void {
    history.pushState(undefined, "Wirder", "/");
  }
}

export const DataLayerContext = createContext<DataLayer>(new DataLayer());

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submittingSolutionScreenContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0px;
}

.submittingSolutionMessageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-bottom: 50px;
}

.submittingSolutionMessage {
  flex: 0;
  font-size: 14pt;
  font-weight: bold;
  margin: 0px 20px 0px 20px;
  text-align: center;
}

.submittingSolutionSubMessage {
  flex: 0;
  font-size: 12pt;
  margin: 8px 20px 8px 20px;
  text-align: center;
}

.tryAgainButton {
  
}

.goHomeButton {
 
}

.submittingButtonText {
  font-size: 12pt;
  margin: 8px;  
}
`, "",{"version":3,"sources":["webpack://./src/screens/submittingSolutionScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,OAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,OAAO;EACP,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,OAAO;EACP,eAAe;EACf,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".submittingSolutionScreenContainer {\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  height: 100vh;\n  width: 100vw;\n  top: 0px;\n}\n\n.submittingSolutionMessageContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  flex: 1;\n  margin-bottom: 50px;\n}\n\n.submittingSolutionMessage {\n  flex: 0;\n  font-size: 14pt;\n  font-weight: bold;\n  margin: 0px 20px 0px 20px;\n  text-align: center;\n}\n\n.submittingSolutionSubMessage {\n  flex: 0;\n  font-size: 12pt;\n  margin: 8px 20px 8px 20px;\n  text-align: center;\n}\n\n.tryAgainButton {\n  \n}\n\n.goHomeButton {\n \n}\n\n.submittingButtonText {\n  font-size: 12pt;\n  margin: 8px;  \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

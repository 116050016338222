import React from "react";
import "./helpPanel.css";

export interface IHelpPanelProps {}
const FIRST_TIME_STORAGE_KEY = "WirderHasShownHelp";

export function HelpPanel(props: IHelpPanelProps) {
  const [showHelp, setShowHelp] = React.useState<boolean>(
    localStorage.getItem(FIRST_TIME_STORAGE_KEY) !== "1",
  );

  React.useEffect(() => {
    if (!showHelp) {
      localStorage.setItem(FIRST_TIME_STORAGE_KEY, "1");
    }
  }, [showHelp]);

  if (showHelp) {
    return (
      <div className="helpPanelContainer" onClick={() => setShowHelp(false)}>
        <div className="helpPanelContent">
          <div className="helpPanelHeader">
            <div className="helpPanelHeaderText">Wirder</div>
            <button className="helpPanelCloseButton" onClick={() => setShowHelp(false)}>
              <div className="helpButtonText">X</div>
            </button>
          </div>
          <div className="helpPanelBody">
            <p>
              Wirder is a new game in early development. Thank you for trying out this prototype and
              sharing your feedback so we can make this a great game!
            </p>
          </div>
          <div className="helpPanelSectionHeader">Goal of the game</div>
          <div className="helpPanelBody">
            <p>
              Wirder is a daily word search game with a twist - you get more points for finding
              "rare" words - words that few other people have found.
            </p>
            <p> Check back each day to see which of your words from the previous day were rare!</p>
          </div>
          <div className="helpPanelSectionHeader">How to play</div>
          <div className="helpPanelBody">
            <p>
              Tap on today's puzzle then start finding words! Words can be formed by connecting
              adjacent letters in any direction, even diagonals. You can reuse letters if they
              appear more than once in a puzzle, but you can't reuse the same square in the same
              word.
            </p>
            <p>
              Tap each letter to select a word, then tap submit. Or, you can simply swipe the word
              all at once!
            </p>
            <p>
              See how many words you can get in today's puzzle before the time runs out. Then
              remember to come back tomorrow to see your final score and if any of the words you
              found were rare!
            </p>
          </div>
          <div className="helpPanelSectionHeader">How to send feedback</div>
          <div className="helpPanelBody">
            <p>
              We need your feedback to make Wirder great. Please email us all of your feedback and
              suggestions by tapping <a href="mailto:beta@wirdergame.com?subject=Feedback">here</a>.
              Thank you!
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="showHelpContainer">
        <button className="showHelpButton" onClick={() => setShowHelp(true)}>
          <div className="helpButtonText">?</div>
        </button>
      </div>
    );
  }
}

import React from "react";
import "./modalDialog.css";

export interface IModalDialogProps {
  dialogConfig?: IModalDialogConfig;
}

export interface IModalDialogConfig {
  title: string;
  message: JSX.Element;
  leftButtonText: string;
  onLeftButtonClick: () => void;
  rightButtonText: string;
  onRightButtonClick: () => void;
}

export function ModalDialog(props: IModalDialogProps) {
  if (!props.dialogConfig) {
    return <></>;
  }

  return (
    <div className="modalDialogContainer">
      <div className="modalDialogInnerContainer">
        <div className="modalDialogTitle">{props.dialogConfig.title}</div>
        <div className="modalDialogMessage">{props.dialogConfig.message}</div>
        <div className="modalDialogButtonContainer">
          <button className="modalDialogButton" onClick={props.dialogConfig.onLeftButtonClick}>
            <div className="modalDialogButtonText">{props.dialogConfig.leftButtonText}</div>
          </button>
          <button className="modalDialogButton" onClick={props.dialogConfig.onRightButtonClick}>
            <div className="modalDialogButtonText">{props.dialogConfig.rightButtonText}</div>
          </button>{" "}
        </div>
      </div>
    </div>
  );
}

export interface IObservable<T> {
  subscribe(callback: (value: T) => void): () => void;
  notify: (value: T) => void;
}

export class Observable<T> implements IObservable<T> {
  private _observers = new Array<(newValue: T) => void>();

  public subscribe(callback: (value: T) => void): () => void {
    this._observers.push(callback);
    return this.internalRemove.bind(this, callback);
  }

  public notify(value: T): void {
    for (const observer of this._observers) {
      observer(value);
    }
  }

  private internalRemove(callback: (value: T) => void): boolean {
    const index = this._observers.indexOf(callback);

    if (index !== -1) {
      this._observers.splice(index, 1);
      return true;
    }

    return false;
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wordInProgressContainer {
  height: 40px;
  display: flex;
  flex-direction: row;
  font-size: 20pt;
  text-align: center;
  margin: 4px;
}

.currentWord {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/screens/components/wordInProgress.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".wordInProgressContainer {\n  height: 40px;\n  display: flex;\n  flex-direction: row;\n  font-size: 20pt;\n  text-align: center;\n  margin: 4px;\n}\n\n.currentWord {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

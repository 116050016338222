import React from "react";
import "./puzzleBoardContainer.css";
import { IPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { PuzzleBoard } from "./puzzleBoard";
import { IObservable, Observable } from "../../observable/observable";
import { extractWord } from "../../gameLogic/extractWord";
import { DictionaryContext } from "../../dataLayer/Dictionary";
import { IWordInPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IWordInPuzzleV1";
import { useObservableProperty } from "../../observable/useObservableProperty";
import { PointerStateManagerContext, Space } from "../../gameLogic/pointerStateManager";

export type PuzzleBoardContainerProps = {
  puzzle: IPuzzleV1;
  pointerUp: IObservable<void>;
  isReadOnly: boolean;
};

/**
 * Contains the puzzle board which is used to play the game. Supports both tapping on squares and dragging / swiping across them.
 * Click vs draw algorithm:
 *   1. When a space gets pointer down, we don't know yet if it's a click or a swipe
 *   2. If the same space gets a pointer up before another space gets a pointer over, it was a click
 */
export function PuzzleBoardContainer(props: PuzzleBoardContainerProps) {
  const pointerStateManager = React.useContext(PointerStateManagerContext);
  if (!pointerStateManager) {
    throw Error("A PointerStateManagerContext is required");
  }
  const [feedbackObservable] = React.useState<IObservable<string>>(new Observable<string>());

  const dictionary = React.useContext(DictionaryContext);

  const onScoreWord = (): void => {
    console.log(`*** onScoreWord`);
    const word: string = extractWord(props.puzzle, pointerStateManager.selectedSpaceList.value);
    const alreadyFound = !!props.puzzle.foundWords.value.find(
      (candidate: IWordInPuzzleV1) => candidate.word.toUpperCase() === word.toUpperCase(),
    );

    if (word.length < 3) {
      feedbackObservable.notify("Too Short");
      onClear();
    } else if (alreadyFound) {
      feedbackObservable.notify("Already Found");
      onClear();
    } else {
      dictionary
        .isValidWord(word)
        .then((isValidWord: boolean) => {
          if (!isValidWord) {
            feedbackObservable.notify("Not In Word List");
            onClear();
          } else {
            const regularPoints: number = props.puzzle.addFoundWord(word);
            feedbackObservable.notify(`+${regularPoints}`);
            onClear();
          }
        })
        .catch((err: unknown) => {
          console.error("Error trying to check if a word was valid", err);
        });
    }
  };

  const onClear = (): void => {
    pointerStateManager.onClear();
  };

  React.useEffect(() => {
    const unsubscribe = pointerStateManager.onScoreWordRequested.subscribe(() => {
      console.log(`*** onScoreWordRequested from pointerStateManager`);
      onScoreWord();
    });
    return () => {
      unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    const unsubscribe = props.pointerUp.subscribe(() => {
      console.log(`*** onPointerUp from page`);
      pointerStateManager.onPointerUp();
    });
    return () => {
      unsubscribe();
    };
  }, [props.pointerUp]);

  return (
    <div
      className="puzzleContainer"
      onClick={(event: React.PointerEvent<HTMLDivElement>) => {
        // Needed so that the page doesn't get the click event and think we want to clear the current word
        event.stopPropagation();
      }}
    >
      <PuzzleBoard
        puzzle={props.puzzle}
        onScoreWord={onScoreWord}
        onClear={onClear}
        feedbackObservable={feedbackObservable}
      />
    </div>
  );
}

import React from "react";
import "../home.css";
import type { PuzzleInListProps } from "./puzzleInList";
import { getDayName } from "../../dataLayer/SimpleDate";

export function PuzzleInListSkipped(props: PuzzleInListProps) {
  return (
    <div className="puzzleInListContainer">
      <div className="puzzleInListDayOfWeek">{getDayName(props.puzzle.date)}</div>
      <div className="puzzleInListCardContents">
        <div className="didNotPlayContainer">Did not play</div>
      </div>
    </div>
  );
}

import React from "react";
import "./puzzleReviewBoard.css";
import { IPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { PuzzleBoardSpace } from "./puzzleBoardSpace";
import { drawArrows } from "./canvas/drawArrows";
import { findWordInBoard } from "../../gameLogic/findWordInBoard";

export type PuzzleReviewBoardProps = {
  puzzle: IPuzzleV1;
  selectedWord: string | undefined;
};

export function PuzzleReviewBoard(props: PuzzleReviewBoardProps) {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const rowCount = props.puzzle.board.length;
  const colCount = props.puzzle.board.length > 0 ? props.puzzle.board[0].length : 0;

  let puzzleRows: React.JSX.Element[] = [];

  React.useEffect(() => {
    if (canvasRef.current) {
      const spaces = props.selectedWord
        ? findWordInBoard(props.puzzle.board, props.selectedWord)
        : [];
      drawArrows(canvasRef.current, spaces);
    }
  }, [canvasRef.current, props.selectedWord]);

  for (let y = 0; y < rowCount; y++) {
    let puzzleRow: React.JSX.Element[] = [];
    for (let x = 0; x < colCount; x++) {
      puzzleRow.push(
        <div className="puzzleReviewBoardSpaceContainer" key={`square_container_${x}_${y}`}>
          <PuzzleBoardSpace
            key={`square_${x}_${y}`}
            letter={props.puzzle.board[y][x]}
            state={"Rest"}
            space={{ x, y }}
          ></PuzzleBoardSpace>
        </div>,
      );
    }

    puzzleRows.push(
      <div className="puzzleReviewBoardRow" key={`row${y}`}>
        {puzzleRow}
      </div>,
    );
  }
  return (
    <div className="puzzleReviewContainer">
      <canvas className="puzzleReviewOverlayCanvas" width="200px" height="200px" ref={canvasRef} />
      <div className="puzzleReviewRowsContainer">{puzzleRows}</div>
    </div>
  );
}

import { createContext } from "react";

import {
  IObservableProperty,
  IReadOnlyObservableProperty,
  ObservableProperty,
} from "../observable/observableProperty";
import { isLegalMove } from "./isLegalMove";
import { IObservable, Observable } from "../observable/observable";
import { IPuzzleV1 } from "../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { PuzzleV1 } from "../dataLayer/v1/PuzzleV1";

export type Space = {
  x: number;
  y: number;
};

type SelectionMode = "none" | "clicking" | "swiping";

export class PointerStateManager {
  public get selectedSpaceList(): IReadOnlyObservableProperty<Space[]> {
    return this._selectedSpaceList;
  }
  public onScoreWordRequested: IObservable<void> = new Observable<void>();
  private _selectedSpaceList: IObservableProperty<Space[]> = new ObservableProperty<Space[]>([]);
  private _puzzle: IPuzzleV1;
  private _selectionMode: SelectionMode = "none";
  private _pointerDownSpace: Space | undefined;

  public constructor(puzzle: IPuzzleV1) {
    this._puzzle = puzzle;
  }

  public onPointerDownOnSquare(space: Space): void {
    console.log(
      `*** PointerDownOnSquare [${space?.x}, ${space?.y}]\tselectionMode: ${this._selectionMode}`,
    );

    // If the user clicked the tail, remove it (this is how they undo)
    if (
      this._selectedSpaceList.value.length > 0 &&
      this._selectedSpaceList.value[this._selectedSpaceList.value.length - 1].x === space.x &&
      this._selectedSpaceList.value[this._selectedSpaceList.value.length - 1].y === space.y
    ) {
      console.log(
        `*** PointerDownOnSquare [${space.x}, ${space.y}]\tselectionMode: ${this._selectionMode}\tRemoving tail`,
      );
      // Remove the tail
      this._selectedSpaceList.value = this._selectedSpaceList.value.slice(0, -1);
    } else {
      console.log(
        `*** PointerDownOnSquare [${space.x}, ${space.y}]\tsetting selectionMode to clicking`,
      );
      this._selectionMode = "clicking";
      this._pointerDownSpace = space;
      this.onTryAddSpaceToSelectedSpaceList(space);
    }
  }

  public onPointerUp(): void {
    console.log(`*** onPointerUp\tselectionMode: ${this._selectionMode}`);
    switch (this._selectionMode) {
      case "none":
        {
          // This may have been cancelled already in onPointerDown - ignore
        }
        break;
      case "swiping":
        {
          this.onScoreWordRequested.notify();
        }
        break;
      case "clicking": {
        // We already selected it, no need to do anything on pointer up
      }
    }
    this._selectionMode = "none";
    this._pointerDownSpace = undefined;
  }

  public onPointerOverLetter(space: Space) {
    if (this._selectionMode === "none") {
      console.log(
        `*** PointerOverLetter [${space.x}, ${space.y}]\tselectionMode: none - so returning...`,
      );
      return;
    }

    switch (this._selectionMode) {
      case "clicking":
        {
          // If we are in clicking mode, but pointer over another square, switch to swiping mode
          if (this._pointerDownSpace?.x !== space.x || this._pointerDownSpace?.y !== space.y) {
            console.log(
              `*** PointerOverLetter: [${space.x}, ${space.y}]\tchanging selectionMode to swiping`,
            );
            this._selectionMode = "swiping";
            this.onTryAddSpaceToSelectedSpaceList(space);
          } else {
            console.log(`*** PointerOverLetter: [${space.x}, ${space.y}]\tignoring`);
          }
        }
        break;
      case "swiping": {
        console.log(`*** PointerOverLetter: [${space.x}, ${space.y}]\selectionMode is swiping`);
        this.onTryAddSpaceToSelectedSpaceList(space);
      }
    }
  }

  public onClear(): void {
    this._selectedSpaceList.value = [];
    this._selectionMode = "none";
    this._pointerDownSpace = undefined;
  }

  private onTryAddSpaceToSelectedSpaceList(space: Space): void {
    console.log(`*** onTryAddSpaceToSelectedSpaceList`);
    if (isLegalMove(space, this._selectedSpaceList.value, this._puzzle.board)) {
      console.log(`*** onTryAddSpaceToSelectedSpaceList - added`);
      this._selectedSpaceList.value = [...this._selectedSpaceList.value, space];
    } else {
      console.log(`*** onTryAddSpaceToSelectedSpaceList - not legal, didn't add`);
    }
  }
}

export const PointerStateManagerContext = createContext<PointerStateManager | undefined>(undefined);

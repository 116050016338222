import React from "react";
import "../reviewPuzzleScreen.css";
import { IPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { IWordInPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IWordInPuzzleV1";

export interface IReviewWordCountsProps {
  puzzle: IPuzzleV1;
  wordsFoundCount: number;
  goldWords: string[];
  silverWords: string[];
  bronzeWords: string[];
}

export function ReviewWordCounts(props: IReviewWordCountsProps) {
  return (
    <>
      <div className="majorSectionHeader">Words Found</div>
      <div className="majorSectionContainer">
        <table>
          <tbody>
            <tr>
              <td>Total:</td>
              <td>{props.wordsFoundCount}</td>
            </tr>
            <tr>
              <td>Rare:</td>

              <td>
                {props.puzzle.isToday
                  ? ""
                  : props.goldWords.length + props.silverWords.length + props.bronzeWords.length}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

import { IPuzzleV1 } from "./interfaces/classes/IPuzzleV1";
import { ISessionV1 } from "./interfaces/classes/ISessionV1";
import { IPuzzleRemoteDataV1 } from "./interfaces/data/IPuzzleRemoteDataV1";
import { ISessionDataV1 } from "./interfaces/data/ISessionDataV1";
import { PuzzleV1 } from "./PuzzleV1";

export class SessionV1 implements ISessionV1 {
  private readonly _data: ISessionDataV1;

  public get userGuid(): string {
    return this._data.userGuid;
  }

  public readonly puzzles: IPuzzleV1[];

  constructor(data: ISessionDataV1) {
    this._data = data;
    this.puzzles = data.puzzles.map((puzzle: IPuzzleRemoteDataV1) => new PuzzleV1(puzzle));
  }

  public serialize(): ISessionDataV1 {
    return {
      userGuid: this.userGuid,
      version: this._data.version,
      puzzles: this.puzzles.map((puzzle: IPuzzleV1) => puzzle.serialize()),
    };
  }
}

import React from "react";
import "./puzzleWord.css";

export interface IPuzzleWordProps {
  word: string;
  isFound?: boolean;
  isSelected?: boolean;
  parenText?: string;
  onClick?: () => void;
}

export function PuzzleWord(props: IPuzzleWordProps) {
  const text = `${props.word}${props.parenText ? ` (${props.parenText})` : ""}`;
  if (props.onClick) {
    return (
      <>
        <a
          className={`clickable${props.isSelected ? " selected" : ""}${
            props.isFound ? " isFound" : ""
          }`}
          onClick={props.onClick}
        >
          {text.toLowerCase()}
        </a>{" "}
      </>
    );
  } else {
    return (
      <span className={`${props.isFound ? "isFound" : undefined}`}>{text.toLowerCase()} </span>
    );
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingScreenContainer {
  display: flex;
  flex-direction: column;
}

.loadingMessageContainer {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadingMessage {
  flex: 0;
  font-size: 14pt;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/screens/loadingScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,QAAQ;;EAER,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,OAAO;EACP,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".loadingScreenContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.loadingMessageContainer {\n  position: absolute;\n  height: 100vh;\n  width: 100vw;\n  top: 0px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.loadingMessage {\n  flex: 0;\n  font-size: 14pt;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedbackOverlay {
  position: fixed;
  margin-top: -10px;
  left: 0px;
  right: 0px;
  font-size: 30pt;
  background-color: rgb(238, 244, 255);
  font-weight: bolder;
  pointer-events: none;
  animation-duration: 2s;
  animation-name: foo;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  scale: 1;
}

@keyframes foo {
  from {
    opacity: 0;
    scale: 1;
    background-color: rgb(238, 244, 255);
  }
  10% {
    opacity: 1;
    scale: 1;
    background-color: rgb(238, 244, 255);
  }
  66% {
    opacity: 1;
    scale: 1;
    background-color: rgb(238, 244, 255);
  }
  67% {
    background-color: transparent;
  }
  99% {
    opacity: 0;
    scale: 3;
    background-color: transparent;
  }
  to {
    opacity: 0;
    background-color: transparent;
  }
}
`, "",{"version":3,"sources":["webpack://./src/screens/components/feedbackOverlay.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,SAAS;EACT,UAAU;EACV,eAAe;EACf,oCAAoC;EACpC,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,mBAAmB;EACnB,6BAA6B;EAC7B,4DAA4D;EAC5D,QAAQ;AACV;;AAEA;EACE;IACE,UAAU;IACV,QAAQ;IACR,oCAAoC;EACtC;EACA;IACE,UAAU;IACV,QAAQ;IACR,oCAAoC;EACtC;EACA;IACE,UAAU;IACV,QAAQ;IACR,oCAAoC;EACtC;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,UAAU;IACV,QAAQ;IACR,6BAA6B;EAC/B;EACA;IACE,UAAU;IACV,6BAA6B;EAC/B;AACF","sourcesContent":[".feedbackOverlay {\n  position: fixed;\n  margin-top: -10px;\n  left: 0px;\n  right: 0px;\n  font-size: 30pt;\n  background-color: rgb(238, 244, 255);\n  font-weight: bolder;\n  pointer-events: none;\n  animation-duration: 2s;\n  animation-name: foo;\n  animation-fill-mode: forwards;\n  animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);\n  scale: 1;\n}\n\n@keyframes foo {\n  from {\n    opacity: 0;\n    scale: 1;\n    background-color: rgb(238, 244, 255);\n  }\n  10% {\n    opacity: 1;\n    scale: 1;\n    background-color: rgb(238, 244, 255);\n  }\n  66% {\n    opacity: 1;\n    scale: 1;\n    background-color: rgb(238, 244, 255);\n  }\n  67% {\n    background-color: transparent;\n  }\n  99% {\n    opacity: 0;\n    scale: 3;\n    background-color: transparent;\n  }\n  to {\n    opacity: 0;\n    background-color: transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

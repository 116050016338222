import type {
  IWordInPuzzleV1,
  RarityLevel,
  RarityStatus,
} from "./interfaces/classes/IWordInPuzzleV1";

export class WordInPuzzleV1 implements IWordInPuzzleV1 {
  public readonly word: string;
  public readonly rarityPoints: number | undefined;
  public readonly regularPoints: number;
  public readonly rarityLevel: RarityLevel | undefined;
  public timesFound: number | undefined;
  public isFound: boolean;

  public get totalPoints(): number {
    return this.regularPoints + (this.rarityPoints || 0);
  }

  public constructor(
    word: string,
    regularPoints: number,
    rarityStatus: RarityStatus | undefined,
    timesFound: number | undefined,
    isFound: boolean,
  ) {
    this.word = word;
    this.regularPoints = regularPoints;
    this.rarityPoints = rarityStatus?.bonus;
    this.rarityLevel = rarityStatus?.rarityLevel;
    this.timesFound = timesFound;
    this.isFound = isFound;
  }

  public serialize(): string {
    return this.word;
  }
}

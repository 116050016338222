// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalDialogContainer {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #ddddddee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalDialogInnerContainer {
  background-color: white;
  margin: 28px;
  border-radius: 8px;
  padding: 18px;
}

.modalDialogTitle {
  font-size: 14pt;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
}

.modalDialogMessage {
  margin-bottom: 8px;
  text-align: center;
}

.modalDialogButtonContainer {
  display: flex;
  flex-direction: row;
  justify-self: center;
  gap: 8px;
}

.modalDialogButton {
  flex: 1;
}

.modalDialogButtonText {
  font-size: 12pt;
  margin: 8px;  
}
`, "",{"version":3,"sources":["webpack://./src/screens/components/modalDialog.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,QAAQ;EACR,WAAW;EACX,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,QAAQ;AACV;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".modalDialogContainer {\n  position: absolute;\n  left: 0px;\n  right: 0px;\n  top: 0px;\n  bottom: 0px;\n  background-color: #ddddddee;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modalDialogInnerContainer {\n  background-color: white;\n  margin: 28px;\n  border-radius: 8px;\n  padding: 18px;\n}\n\n.modalDialogTitle {\n  font-size: 14pt;\n  font-weight: bold;\n  margin-bottom: 8px;\n  text-align: center;\n}\n\n.modalDialogMessage {\n  margin-bottom: 8px;\n  text-align: center;\n}\n\n.modalDialogButtonContainer {\n  display: flex;\n  flex-direction: row;\n  justify-self: center;\n  gap: 8px;\n}\n\n.modalDialogButton {\n  flex: 1;\n}\n\n.modalDialogButtonText {\n  font-size: 12pt;\n  margin: 8px;  \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { useContext } from "react";
import { DataLayerContext } from "../dataLayer/DataLayer";
import "./reviewPuzzleScreen.css";
import { Header } from "./components/header";
import { getDateString, getDayName } from "../dataLayer/SimpleDate";
import { useObservableProperty } from "../observable/useObservableProperty";
import { IPuzzleV1 } from "../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { PuzzleReviewBoard } from "./components/puzzleReviewBoard";
import { ReviewWordCounts } from "./components/reviewWordCounts";
import { ReviewRareWords } from "./components/reviewRareWords";
import { ReviewAllWords } from "./components/reviewAllWords";
import { IWordInPuzzleV1 } from "../dataLayer/v1/interfaces/classes/IWordInPuzzleV1";
import { ReviewPoints } from "./components/reviewPoints";
import { generateShareResultsText } from "../helpers/generateShareResultsText";

export interface IReviewPuzzleScreenProps {
  puzzle: IPuzzleV1;
}

export function ReviewPuzzleScreen(props: IReviewPuzzleScreenProps) {
  const dataLayer = useContext(DataLayerContext);

  const allWords = useObservableProperty<IWordInPuzzleV1[]>(props.puzzle.allWords || []);
  const [goldWords, setGoldWords] = React.useState<string[]>([]);
  const [silverWords, setSilverWords] = React.useState<string[]>([]);
  const [bronzeWords, setBronzeWords] = React.useState<string[]>([]);
  const [selectedWord, setSelectedWord] = React.useState<string | undefined>();
  const totalScore = useObservableProperty<number>(props.puzzle.totalScore);
  const rarityPoints = useObservableProperty<number | undefined>(props.puzzle.rarityPoints);
  const regularPoints = useObservableProperty<number | undefined>(props.puzzle.regularPoints);
  const [foundWordsCount, setFoundWordsCount] = React.useState<number>(0);

  const [wordsToDisplayByLength, setWordsToDisplayByLength] = React.useState<IWordInPuzzleV1[][]>(
    [],
  );

  React.useEffect(() => {
    let _foundWordsCount = 0;
    let goldWordList: string[] = [];
    let silverWordList: string[] = [];
    let bronzeWordList: string[] = [];
    const _wordsToDisplayByLength: IWordInPuzzleV1[][] = [];
    allWords.forEach((value: IWordInPuzzleV1) => {
      if (value.isFound) {
        _foundWordsCount++;

        switch (value.rarityLevel) {
          case "Gold":
            {
              goldWordList.push(value.word);
            }
            break;
          case "Silver":
            {
              silverWordList.push(value.word);
            }
            break;
          case "Bronze":
            {
              bronzeWordList.push(value.word);
            }
            break;
        }
      }

      // Only show words if they were found or the puzzle wasn't from today
      if (value.isFound || !props.puzzle.isToday) {
        if (_wordsToDisplayByLength[value.word.length] === undefined) {
          _wordsToDisplayByLength[value.word.length] = [value];
        } else {
          _wordsToDisplayByLength[value.word.length].push(value);
        }
      }
    });

    // Sort the lists
    for (let wordLength = 0; wordLength <= _wordsToDisplayByLength.length; wordLength++) {
      if (_wordsToDisplayByLength[wordLength]) {
        _wordsToDisplayByLength[wordLength].sort((a: IWordInPuzzleV1, b: IWordInPuzzleV1) => {
          if (a.word < b.word) {
            return -1;
          } else {
            return 1;
          }
        });
      }
    }

    setFoundWordsCount(_foundWordsCount);
    setGoldWords(goldWordList);
    setSilverWords(silverWordList);
    setBronzeWords(bronzeWordList);
    setWordsToDisplayByLength(_wordsToDisplayByLength);
  }, [allWords]);

  const onBackClicked = () => {
    dataLayer.currentPuzzle.value = undefined;
    dataLayer.currentScreen.value = "Home";
  };

  if (dataLayer.currentPuzzle.value === undefined) {
    dataLayer.currentScreen.value = "Home";
    return <></>;
  }

  const onShareClicked = () => {
    navigator
      .share({
        text: generateShareResultsText(
          props.puzzle,
          wordsToDisplayByLength,
          foundWordsCount,
          regularPoints,
          rarityPoints,
          totalScore,
        ),
      })
      .catch((e) => {
        console.warn("Share threw exception:", e);
      });
  };

  return (
    <div className="reviewPuzzleScreen">
      <Header onBackClicked={onBackClicked} onShareClicked={onShareClicked}></Header>
      <div className="contentColumn">
        <div className="nonScrollableSection">
          <div className="whichDayContainer">
            <div className="dayOfWeek">{getDayName(dataLayer.currentPuzzle.value.date)}</div>
            <div className="date">{getDateString(dataLayer.currentPuzzle.value.date)}</div>
          </div>
          {props.puzzle.isToday ? (
            <div className="checkBacktomorrow">
              Check tomorrow to see your rare words, total score, and more puzzle stats!
            </div>
          ) : (
            <></>
          )}
          <div className="columnContainer">
            <div className="leftSide">
              <ReviewPoints
                puzzle={props.puzzle}
                totalScore={totalScore}
                regularPoints={regularPoints}
                rarityPoints={rarityPoints}
              />
              <ReviewWordCounts
                puzzle={props.puzzle}
                wordsFoundCount={foundWordsCount}
                goldWords={goldWords}
                silverWords={silverWords}
                bronzeWords={bronzeWords}
              />
            </div>
            <div className="rightSide">
              <div className="majorSectionHeader"></div>
              <PuzzleReviewBoard puzzle={props.puzzle} selectedWord={selectedWord} />
            </div>
          </div>
        </div>
        <div className="scrollableContentContainerWrapper">
          <div className="scrollableContentContainer">
            <div className="scrollableContent">
              <ReviewRareWords
                puzzle={props.puzzle}
                selectedWord={selectedWord}
                goldWords={goldWords}
                silverWords={silverWords}
                bronzeWords={bronzeWords}
                setSelectedWord={setSelectedWord}
              />
              <ReviewAllWords
                puzzle={props.puzzle}
                wordsToDisplayByLength={wordsToDisplayByLength}
                selectedWord={selectedWord}
                setSelectedWord={setSelectedWord}
              />

              <div className="majorSectionHeader">Community Stats</div>
              <div className="majorSectionContainer">
                Total plays:{" "}
                {props.puzzle.isToday ? "Check back tomorrow!" : props.puzzle.totalPlays}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Space } from "../../../gameLogic/pointerStateManager";

const BORDER = 5;
const BOX_SIZE = 40;
const ALPHAS = ["CC", "BB", "AA", "99", "99", "88", "88", "77", "77"];
const STROKE_COLOR = "#a2cbfa";

export function drawArrows(canvas: HTMLCanvasElement, spaces: Space[]): void {
  const context = canvas.getContext("2d");
  if (context === null) {
    console.error("Could not get context for drawing arrows");
    return;
  }
  context.clearRect(0, 0, 200, 200);
  if (spaces.length < 1) {
    return;
  }

  context.fillStyle = "lightblue";
  context.lineWidth = 10;
  context.lineCap = "round";

  for (let i = 1; i < spaces.length; i++) {
    const path = new Path2D();
    path.moveTo(coordToPixels(spaces[i - 1].x), coordToPixels(spaces[i - 1].y));
    context.strokeStyle = "#a2cbfa" + ALPHAS[Math.min(ALPHAS.length - 1, i - 1)];
    path.lineTo(coordToPixels(spaces[i].x), coordToPixels(spaces[i].y));
    context.stroke(path);
  }
}

function coordToPixels(grid: number): number {
  return BOX_SIZE * 0.5 + BORDER + grid * (BOX_SIZE + BORDER * 2);
}

import { SimpleDate } from "./SimpleDate";

export function getSimpleDateInSeattle(): SimpleDate {
  const now = new Date(Date.now());
  const nowInSeattleString = now.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
  const nowInSeattleDate: Date = new Date(nowInSeattleString);

  return {
    month: nowInSeattleDate.getMonth() + 1,
    day: nowInSeattleDate.getDate(),
    year: nowInSeattleDate.getFullYear(),
  };
}

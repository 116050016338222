import React, { useContext, useRef } from "react";
import { DataLayerContext } from "./dataLayer/DataLayer";
import { useObservableProperty } from "./observable/useObservableProperty";
import { HomeScreen } from "./screens/home";
import { LoadingScreen } from "./screens/loadingScreen";
import "./styles.css";
import { PuzzleScreen } from "./screens/puzzleScreen";
import { ReviewPuzzleScreen } from "./screens/reviewPuzzleScreen";
import { SubmittingSolutionScreen } from "./screens/submittingSolutionScreen";
import { PointerStateManager, PointerStateManagerContext } from "./gameLogic/pointerStateManager";
import { PlayerStatsScreen } from "./screens/playerStatsScreen";

export function App() {
  const dataLayer = useContext(DataLayerContext);
  const currentScreen = useObservableProperty(dataLayer.currentScreen);

  switch (currentScreen) {
    case "Loading":
      return <LoadingScreen></LoadingScreen>;
    case "Home":
      return <HomeScreen></HomeScreen>;
    case "Puzzle":
      if (dataLayer.currentPuzzle.value) {
        return (
          <PointerStateManagerContext.Provider
            value={new PointerStateManager(dataLayer.currentPuzzle.value)}
          >
            <PuzzleScreen></PuzzleScreen>
          </PointerStateManagerContext.Provider>
        );
      } else {
        return <></>;
      }
    case "SubmittingSolution":
      return <SubmittingSolutionScreen></SubmittingSolutionScreen>;
    case "ReviewPuzzle":
      if (dataLayer.currentPuzzle.value) {
        return <ReviewPuzzleScreen puzzle={dataLayer.currentPuzzle.value}></ReviewPuzzleScreen>;
      }
    case "PlayerStats": {
      return <PlayerStatsScreen></PlayerStatsScreen>;
    }
    default:
      return <></>;
  }
}

import React from "react";
import "./header.css";

export type HeaderProps = {
  onBackClicked?: () => void;
  onShareClicked?: () => void;
};

export function Header(props: HeaderProps) {
  return (
    <div className="headerContainer">
      {props.onBackClicked ? (
        <div className="backButtonContainer">
          <div className="backButton" onClick={props.onBackClicked}>
            &lt;
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="titleContainer">
        <h1>Wirder</h1>
      </div>
      {props.onShareClicked ? (
        <div className="shareButtonContainer">
          <button onClick={props.onShareClicked}>
            <div className="buttonText">Share</div>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./app";
import { DataLayer, DataLayerContext } from "./dataLayer/DataLayer";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <DataLayerContext.Provider value={new DataLayer()}>
    <App />
  </DataLayerContext.Provider>,
);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.playerStatsScreen {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  background-color: rgb(238, 244, 255);
  align-items: center;
}

.contentColumn {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-height: 0px;
  overflow-y: auto;
}

.statsTable {
  border-collapse: collapse;
  margin: 0px 4px;
}

.statsTable thead th {
  border: 1px solid black;
  padding: 1px 10px;
}

.statsTable tbody td {
  border: 1px solid black;
  padding: 1px 10px;
}

.sectionContainer {
  margin: 2px 8px 10px 18px;
}

.sectionHeader {
  font-size: 12pt;
  font-weight: bold;
  margin-top: 12px;
}

.sectionSubHeader {
  font-size: 10pt;
  margin-bottom: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/playerStatsScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,OAAO;EACP,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".playerStatsScreen {\n  display: flex;\n  flex-direction: column;\n  height: 100dvh;\n  background-color: rgb(238, 244, 255);\n  align-items: center;\n}\n\n.contentColumn {\n  max-width: 900px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex: 1;\n  min-height: 0px;\n  overflow-y: auto;\n}\n\n.statsTable {\n  border-collapse: collapse;\n  margin: 0px 4px;\n}\n\n.statsTable thead th {\n  border: 1px solid black;\n  padding: 1px 10px;\n}\n\n.statsTable tbody td {\n  border: 1px solid black;\n  padding: 1px 10px;\n}\n\n.sectionContainer {\n  margin: 2px 8px 10px 18px;\n}\n\n.sectionHeader {\n  font-size: 12pt;\n  font-weight: bold;\n  margin-top: 12px;\n}\n\n.sectionSubHeader {\n  font-size: 10pt;\n  margin-bottom: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { Space } from "./pointerStateManager";

export function isLegalMove(newSpace: Space, selectedSpaces: Space[], board: string[][]): boolean {
  const rowCount = board.length;
  const colCount = board.length > 0 ? board[0].length : 0;

  // Check that x is valid
  if (newSpace.x < 0 || newSpace.x >= colCount) {
    return false;
  }

  // Check that y is valid
  if (newSpace.y < 0 || newSpace.y >= rowCount) {
    return false;
  }

  // Ensure that it's not already selected
  if (
    !!selectedSpaces.find(
      (candidate: Space) => candidate.x === newSpace.x && candidate.y === newSpace.y,
    )
  ) {
    return false;
  }

  // Ensure it's adjacent to the tail of the currently selected spaces
  if (selectedSpaces.length === 0) {
    return true;
  }
  const tail: Space = selectedSpaces[selectedSpaces.length - 1];
  if (Math.abs(newSpace.x - tail.x) > 1) {
    return false;
  }
  if (Math.abs(newSpace.y - tail.y) > 1) {
    return false;
  }

  // Looks good!
  return true;
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.puzzleScreen {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  background-color: rgb(238, 244, 255);
  user-select: none; 
  -webkit-user-select: none;
}

.playArea {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  touch-action: none;
}

.playAreaColumn {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/puzzleScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,oCAAoC;EACpC,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,OAAO;EACP,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,OAAO;EACP,gBAAgB;AAClB","sourcesContent":[".puzzleScreen {\n  display: flex;\n  flex-direction: column;\n  height: 100dvh;\n  background-color: rgb(238, 244, 255);\n  user-select: none; \n  -webkit-user-select: none;\n}\n\n.playArea {\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  justify-content: center;\n  touch-action: none;\n}\n\n.playAreaColumn {\n  max-width: 900px;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  max-width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.helpPanelContainer {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    background-color: #dddddd99;
}

.helpPanelContent {
    max-width: 500px;
    background-color: white;
    padding: 8px;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    margin-top: 100px;
    overflow-y: auto;
}

.helpPanelHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
}

.helpPanelHeaderText {
    font-size: 16pt;
    font-weight: bold;
}
.helpPanelCloseButton {
    font-size: 14pt;
}

.helpPanelBody {
    margin-left: 8px;
    font-size: 10pt;
}

.helpPanelBody p {
    margin-top: 4px;
}

.helpPanelSectionHeader {
    font-size: 12pt;
    font-weight: bold;
}

.showHelpContainer {
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.showHelpButton {
    margin: 18px;
    font-size: 14pt;
}

.helpButtonText {
    font-size: 12pt;
    margin: 8px;  
}`, "",{"version":3,"sources":["webpack://./src/screens/components/helpPanel.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,UAAU;IACV,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,oBAAoB;IACpB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,YAAY;IACZ,2BAA2B;IAC3B,4BAA4B;IAC5B,6BAA6B;IAC7B,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,WAAW;AACf","sourcesContent":[".helpPanelContainer {\n    position: absolute;\n    top: 0px;\n    bottom: 0px;\n    right: 0px;\n    left: 0px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: end;\n    background-color: #dddddd99;\n}\n\n.helpPanelContent {\n    max-width: 500px;\n    background-color: white;\n    padding: 8px;\n    border-top: 1px solid black;\n    border-left: 1px solid black;\n    border-right: 1px solid black;\n    margin-top: 100px;\n    overflow-y: auto;\n}\n\n.helpPanelHeader {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;    \n}\n\n.helpPanelHeaderText {\n    font-size: 16pt;\n    font-weight: bold;\n}\n.helpPanelCloseButton {\n    font-size: 14pt;\n}\n\n.helpPanelBody {\n    margin-left: 8px;\n    font-size: 10pt;\n}\n\n.helpPanelBody p {\n    margin-top: 4px;\n}\n\n.helpPanelSectionHeader {\n    font-size: 12pt;\n    font-weight: bold;\n}\n\n.showHelpContainer {\n    position: absolute;\n    bottom: 0px;\n    right: 0px;\n    display: flex;\n    flex-direction: row;\n    justify-content: end;\n}\n\n.showHelpButton {\n    margin: 18px;\n    font-size: 14pt;\n}\n\n.helpButtonText {\n    font-size: 12pt;\n    margin: 8px;  \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

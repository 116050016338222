import React from "react";
import "./wordInProgress.css";
import { FeedbackOverlay } from "./feedbackOverlay";
import { IObservable } from "../../observable/observable";

export type WordInProgressProps = {
  word: string;
  feedbackObservable: IObservable<string>;
};

export function WordInProgress(props: WordInProgressProps) {
  return (
    <div className="wordInProgressContainer">
      <FeedbackOverlay feedbackObservable={props.feedbackObservable} />
      <div className="currentWord">{props.word}</div>
    </div>
  );
}

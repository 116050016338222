import React from "react";
import "./puzzleBoard.css";
import { PointerStateManagerContext, Space } from "../../gameLogic/pointerStateManager";

export type SpaceState = "Rest" | "Included" | "Tail";
export type PuzzelBoardSpaceProps = {
  letter: string;
  state: SpaceState;
  space: Space;
};

export function PuzzleBoardSpace(props: PuzzelBoardSpaceProps) {
  const pointerStateManager = React.useContext(PointerStateManagerContext);
  const containerDivRef = React.useRef<HTMLDivElement>(null);
  const letterDivRef = React.useRef<HTMLDivElement>(null);
  const letterHitTargetDivRef = React.useRef<HTMLDivElement>(null);

  let className = "puzzleBoardSpace";
  if (pointerStateManager) {
    className += " clickable";
  }
  switch (props.state) {
    case "Included":
      className += " included";
      break;
    case "Rest":
      className += " atRest";
      break;
    case "Tail":
      className += " tail";
      break;
  }

  return (
    <div
      className={className}
      ref={containerDivRef}
      onPointerUp={(event: React.PointerEvent<HTMLDivElement>) => {
        pointerStateManager && pointerStateManager.onPointerUp();
        event.stopPropagation();
        event.preventDefault();
      }}
      onPointerDown={(event: React.PointerEvent<HTMLDivElement>) => {
        pointerStateManager && pointerStateManager.onPointerDownOnSquare(props.space);
        if (containerDivRef.current) {
          // Mobile browsers implicitly capture the pointer, but we want other squares to be able to get pointer over events, etc.
          containerDivRef.current.releasePointerCapture(event.pointerId);
        }
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div
        className="puzzleBoardSpaceLetterDiv"
        ref={letterDivRef}
        onPointerDown={(event: React.PointerEvent<HTMLDivElement>) => {
          if (letterDivRef.current) {
            // Mobile browsers implicitly capture the pointer, but we want other squares to be able to get pointer over events, etc.
            letterDivRef.current.releasePointerCapture(event.pointerId);
          }
        }}
      >
        <div className="puzzleBoardSpaceLetter">{props.letter}</div>
      </div>
      <div
        className="puzzleBoardSpaceLetterHitTarget"
        ref={letterHitTargetDivRef}
        onPointerOver={() => {
          pointerStateManager?.onPointerOverLetter(props.space);
        }}
        onPointerDown={(event: React.PointerEvent<HTMLDivElement>) => {
          if (letterHitTargetDivRef.current) {
            // Mobile browsers implicitly capture the pointer, but we want other squares to be able to get pointer over events, etc.
            letterHitTargetDivRef.current.releasePointerCapture(event.pointerId);
          }
        }}
      />
    </div>
  );
}

import React, { useContext } from "react";
import { DataLayerContext, SubmitSolutionResult } from "../dataLayer/DataLayer";
import "./submittingSolutionScreen.css";
import { Header } from "./components/header";

type ScreenState = "Submitting" | "OK" | "Error" | "DuplicateSubmission";

export function SubmittingSolutionScreen() {
  const dataLayer = useContext(DataLayerContext);
  const [screenState, setScreenState] = React.useState<ScreenState>("Submitting");

  const onBackClicked = () => {
    dataLayer.currentPuzzle.value = undefined;
    dataLayer.currentScreen.value = "Home";
  };

  const doSubmission = () => {
    console.log("doSubmission");
    setScreenState("Submitting");
    dataLayer
      .submitPuzzle()
      .then((submitResult: SubmitSolutionResult) => {
        switch (submitResult) {
          case "Success":
            setScreenState("OK");
            break;
          case "InvalidDate":
          case "GuidNotFound":
          case "Error":
            setScreenState("Error");
            break;
          case "SolutionAlreadySubmitted":
            setScreenState("DuplicateSubmission");
            break;
        }
      })
      .catch((error: unknown) => {
        console.error("Failed to submit puzzle", error);
      });
  };

  React.useEffect(() => {
    doSubmission();
  }, []);

  let message: JSX.Element;
  switch (screenState) {
    case "Submitting":
      {
        message = <div className="submittingSolutionMessage">Submitting Solution...</div>;
      }
      break;

    case "DuplicateSubmission":
      {
        message = (
          <>
            <div className="submittingSolutionMessage">Duplicate Solution</div>
            <div className="submittingSolutionSubMessage">
              It looks like you already solved this puzzle from another device, so this solution
              wasn't saved. Sorry, but only your first solution for the day counts.
            </div>
            <button className="goHomeButton" onClick={onBackClicked}>
              <div className="submittingButtonText">Home</div>
            </button>
          </>
        );
      }
      break;
    case "OK":
      {
        message = (
          <>
            <div className="submittingSolutionMessage">Your solution has been submitted!</div>
            <div className="submittingSolutionSubMessage">
              Check back tomorrow to see if any of the words you found earned rarity bonuses
            </div>
            <button className="goHomeButton" onClick={onBackClicked}>
              <div className="submittingButtonText">Home</div>
            </button>
          </>
        );
      }
      break;
    default:
      {
        if (screenState !== "Error") {
          console.error("Unexpected screenState", screenState);
        }
        message = (
          <>
            <div className="submittingSolutionMessage">
              There was an error submitting your solution
            </div>
            <button className="tryAgainButton" onClick={doSubmission}>
              <div className="submittingButtonText">Try Again</div>
            </button>
          </>
        );
      }
      break;
  }

  return (
    <div className="submittingSolutionScreenContainer">
      <Header onBackClicked={screenState !== "Submitting" ? onBackClicked : undefined} />
      <div className="submittingSolutionMessageContainer">{message}</div>
    </div>
  );
}

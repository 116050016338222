import { getDateString, getDayName } from "../dataLayer/SimpleDate";
import { IPuzzleV1 } from "../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { IWordInPuzzleV1 } from "../dataLayer/v1/interfaces/classes/IWordInPuzzleV1";
import { numberToWord } from "./numberToWord";

export function generateShareResultsText(
  puzzle: IPuzzleV1,
  wordsToDisplayByLength: IWordInPuzzleV1[][],
  foundWordsCount: number,
  regularPoints: number | undefined,
  rarityPoints: number | undefined,
  totalScore: number | undefined,
): string {
  let text: string;

  function appendLongWordStats() {
    for (let wordLength = 7; wordLength <= 16; wordLength++) {
      let countOfWords = 0;
      if (wordsToDisplayByLength[wordLength]) {
        wordsToDisplayByLength[wordLength].forEach((value: IWordInPuzzleV1) => {
          if (value.isFound) {
            countOfWords++;
          }
        });
      }
      if (countOfWords > 0) {
        text += `\n - Found ${countOfWords} ${numberToWord(wordLength)} letter ${
          countOfWords > 1 ? "words" : "word"
        }`;
      }
    }
  }

  if (puzzle.isToday) {
    text = `My Wirder stats from today:\n - Found ${foundWordsCount} ${
      foundWordsCount > 1 ? "words" : "word"
    }\n - Got ${regularPoints?.toLocaleString()} regular points`;
    appendLongWordStats();
  } else {
    const day = `${getDayName(puzzle.date)} ${getDateString(puzzle.date)}`;
    text = `My Wirder stats from ${day}\n - Found ${foundWordsCount} ${
      foundWordsCount > 1 ? "words" : "word"
    }\n - Got ${totalScore?.toLocaleString()} total points (${regularPoints?.toLocaleString()} regular, ${rarityPoints?.toLocaleString()} rarity points)`;
    appendLongWordStats();
  }
  text +=
    "\n\nHave you played Wirder today?\nPlay the beta here! https://www.wirdergame.com\nSend your feedback to: beta@wirdergame.com";

  return text;
}

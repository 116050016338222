import React, { useContext } from "react";
import { DataLayerContext } from "../dataLayer/DataLayer";
import "./puzzleScreen.css";
import { Header } from "./components/header";
import { ScorePanel } from "./components/scorePanel";
import { PuzzleBoardContainer } from "./components/puzzleBoardContainer";
import { Observable } from "../observable/observable";
import { IModalDialogConfig, ModalDialog } from "./components/modalDialog";

type ScreenState = "Normal" | "AskingToConfirmSubmit" | "AskingToConfirmDeparture";

export function PuzzleScreen() {
  const dataLayer = useContext(DataLayerContext);
  const pointerUp = new Observable<void>();
  const [screenState, setScreenState] = React.useState<ScreenState>("Normal");

  /**
   * The number of seconds spent on this puzzle in previous sessions.
   * A new session starts if the user navigates away then comes back.
   */
  const [timeInPreviousSessionsInSeconds] = React.useState<number>(
    dataLayer.currentPuzzle.value?.timeElapsedInSeconds || 0,
  );
  const [timestampAtScreenLoad] = React.useState<number>(Date.now());
  const [modalDialogConfig, setModalDialogConfig] = React.useState<
    IModalDialogConfig | undefined
  >();
  const [timeRemainingInSeconds, setTimeRemainingInSeconds] = React.useState<number>(
    (dataLayer.currentPuzzle.value?.timeLimitInSeconds || 0) - timeInPreviousSessionsInSeconds,
  );

  /** Updates timeElapsedInSeconds in the current puzzle */
  const updateTimeElapsedInSeconds = () => {
    if (dataLayer.currentPuzzle.value) {
      dataLayer.currentPuzzle.value.timeElapsedInSeconds =
        timeInPreviousSessionsInSeconds + (Date.now() - timestampAtScreenLoad) / 1000;
    }
  };

  /** Saves the current state */
  const save = () => {
    updateTimeElapsedInSeconds();
    dataLayer.save();
  };

  const onBackClicked = async () => {
    const leaveNow = () => {
      dataLayer.currentPuzzle.value = undefined;
      dataLayer.currentScreen.value = "Home";
      save();
    };
    if (
      dataLayer.currentPuzzle.value?.foundWords.value?.length !== undefined &&
      dataLayer.currentPuzzle.value?.foundWords.value?.length > 0
    ) {
      setModalDialogConfig({
        title: "Leave Now?",
        message:
          <p>If you leave now the timer will pause, but you'll need to be sure to come back and finish before the end of the day to get credit for the words you found!</p>,
        leftButtonText: "Stay",
        onLeftButtonClick: () => {
          setScreenState("Normal");
          setModalDialogConfig(undefined);
        },
        rightButtonText: "Leave Now",
        onRightButtonClick: leaveNow,
      });
      setScreenState("AskingToConfirmSubmit");
    } else {
      leaveNow();
    }
  };

  React.useEffect(() => {
    const handle = setInterval(() => {
      const timeOnPageInSeconds = (Date.now() - timestampAtScreenLoad) / 1000;
      const totalTimeInSeconds = timeOnPageInSeconds + timeInPreviousSessionsInSeconds;
      const newTimeRemainingInSeconds = Math.max(
        0,
        (dataLayer.currentPuzzle.value?.timeLimitInSeconds || 0) - totalTimeInSeconds,
      );
      setTimeRemainingInSeconds(newTimeRemainingInSeconds);

      // Save on every tick so that if the user leaves or reloades, their state is not stale
      save();

      if (newTimeRemainingInSeconds === 0) {
        dataLayer.currentScreen.value = "SubmittingSolution";
      }
    }, 1000);
    return () => {
      clearInterval(handle);
    };
  }, [timestampAtScreenLoad, setTimeRemainingInSeconds]);

  return (
    <div className="puzzleScreen" onPointerUp={() => pointerUp.notify()}>
      <Header onBackClicked={onBackClicked}></Header>
      {dataLayer.currentPuzzle.value ? (
        <div className="playArea">
          <div className="playAreaColumn">
            <ScorePanel
              puzzle={dataLayer.currentPuzzle.value}
              timeRemainingInSeconds={timeRemainingInSeconds}
            ></ScorePanel>

            <PuzzleBoardContainer
              puzzle={dataLayer.currentPuzzle.value}
              isReadOnly={!dataLayer.currentPuzzle.value.isPlayable}
              pointerUp={pointerUp}
            ></PuzzleBoardContainer>
          </div>
        </div>
      ) : (
        <></>
      )}
      <ModalDialog dialogConfig={modalDialogConfig}></ModalDialog>
    </div>
  );
}

export function numberToOrdinalString(num: number) {
  const onesPlace = num % 10;
  const tensPlace = num % 100;

  if (onesPlace === 1 && tensPlace !== 11) {
    return num + "st";
  }
  if (onesPlace === 2 && tensPlace !== 12) {
    return num + "nd";
  }
  if (onesPlace === 3 && tensPlace !== 13) {
    return num + "rd";
  }
  return num + "th";
}

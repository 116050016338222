import React from "react";
import "../reviewPuzzleScreen.css";
import { IPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { PuzzleWord } from "./puzzleWord";

export interface IReviewRareWordsProps {
  puzzle: IPuzzleV1;
  selectedWord: string | undefined;
  setSelectedWord: (word: string | undefined) => void;
  goldWords: string[];
  silverWords: string[];
  bronzeWords: string[];
}

export function ReviewRareWords(props: IReviewRareWordsProps) {
  if (props.puzzle.isToday) {
    return <></>;
  }

  return (
    <>
      <div className="majorSectionHeader">Rare Words</div>
      <div className="majorSectionContainer">
        {props.puzzle.isToday ? "Check back tomorrow!" : <></>}
        {props.goldWords.length > 0 ? (
          <>
            <div className="rareHeader gold">
              Gold ({props.puzzle.scoringRubric.goldCriteria.bonus}pts. each)
            </div>
            <div className="sectionContainer">
              {props.goldWords.map((word: string) => {
                return (
                  <PuzzleWord
                    key={`goldWord_${word}`}
                    word={word}
                    isSelected={word === props.selectedWord}
                    onClick={() => {
                      if (word === props.selectedWord) {
                        props.setSelectedWord(undefined);
                      } else {
                        props.setSelectedWord(word);
                      }
                    }}
                  ></PuzzleWord>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
        {props.silverWords.length > 0 ? (
          <>
            <div className="rareHeader silver">
              Silver ({props.puzzle.scoringRubric.silverCriteria.bonus}
              pts. each)
            </div>
            <div className="sectionContainer">
              {props.silverWords.map((word: string) => {
                return (
                  <PuzzleWord
                    key={`silverWord_${word}`}
                    word={word}
                    isSelected={word === props.selectedWord}
                    onClick={() => {
                      if (word === props.selectedWord) {
                        props.setSelectedWord(undefined);
                      } else {
                        props.setSelectedWord(word);
                      }
                    }}
                  ></PuzzleWord>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
        {props.bronzeWords.length > 0 ? (
          <>
            <div className="rareHeader bronze">
              Bronze ({props.puzzle.scoringRubric.bronzeCriteria.bonus}
              pts. each)
            </div>
            <div className="sectionContainer">
              {props.bronzeWords.map((word: string) => {
                return (
                  <PuzzleWord
                    key={`bronzeWord_${word}`}
                    word={word}
                    isSelected={word === props.selectedWord}
                    onClick={() => {
                      if (word === props.selectedWord) {
                        props.setSelectedWord(undefined);
                      } else {
                        props.setSelectedWord(word);
                      }
                    }}
                  ></PuzzleWord>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

import React, { useContext, useState } from "react";
import { DataLayerContext } from "../dataLayer/DataLayer";
import "./playerStatsScreen.css";
import { Header } from "./components/header";
import { IPlayerStat, IPlayerStats } from "../dataLayer/v1/interfaces/data/IPlayerStats";
import { SimpleDate, getDateString } from "../dataLayer/SimpleDate";
import { numberToOrdinalString } from "../helpers/numberToOrdinalString";

export function PlayerStatsScreen() {
  const dataLayer = useContext(DataLayerContext);
  const [playerStats, setPlayerStats] = useState<IPlayerStats | undefined>();

  React.useEffect(() => {
    dataLayer
      .getPlayerStats()
      .then((playerStatsFromDataLayer: IPlayerStats | undefined) =>
        setPlayerStats(playerStatsFromDataLayer),
      );
  }, [dataLayer, setPlayerStats]);

  const onBackClicked = () => {
    dataLayer.currentPuzzle.value = undefined;
    dataLayer.currentScreen.value = "Home";
  };

  const onNavigateToPuzzleByDate = (date: SimpleDate) => {
    dataLayer.currentPuzzle.value = dataLayer.findPuzzleByDate(date);
    dataLayer.currentScreen.value = "ReviewPuzzle";
  };

  return (
    <div className="playerStatsScreen">
      <Header onBackClicked={onBackClicked}></Header>
      <div className="contentColumn">
        <div className="sectionHeader">Overall Stats</div>
        <table>
          <tbody>
            <tr>
              <td className="label">Games Played:</td>
              <td className="value">{playerStats?.totalPlays}</td>
            </tr>
          </tbody>
        </table>
        <div className="sectionHeader">Personal Records</div>
        <div className="sectionSubHeader">(Placing is out of total community plays)</div>
        <table className="statsTable">
          <tbody>
            <thead>
              <tr>
                <th scope="col">Stat</th>
                <th scope="col">Value</th>
                <th scope="col">Place</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {displayStat("Total Words", playerStats?.mostFoundWords, onNavigateToPuzzleByDate)}
              {displayStat("Total Points", playerStats?.totalPoints, onNavigateToPuzzleByDate)}
              {displayStat("Reg. Points", playerStats?.regularPoints, onNavigateToPuzzleByDate)}
              {displayStat("Rarity Points", playerStats?.rarityPoints, onNavigateToPuzzleByDate)}
              {displayLengthRecords(playerStats, onNavigateToPuzzleByDate)}
            </tbody>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function displayLengthRecords(
  playerStats: IPlayerStats | undefined,
  onNavigateToPuzzleByDate: (date: SimpleDate) => void,
) {
  if (!playerStats) {
    return <></>;
  }

  const rows: JSX.Element[] = [];
  for (let num = 16; num >= 3; num--) {
    rows.push(
      displayOptionalStat(`${num - 1} letters`, playerStats.letters[num], onNavigateToPuzzleByDate),
    );
  }

  return <>{rows}</>;
}

function displayOptionalStat(
  title: string,
  stat: IPlayerStat | undefined,
  onNavigateToPuzzleByDate: (date: SimpleDate) => void,
): JSX.Element {
  return stat ? displayStat(title, stat, onNavigateToPuzzleByDate) : <></>;
}

function displayStat(
  title: string,
  stat: IPlayerStat | undefined,
  onNavigateToPuzzleByDate: (date: SimpleDate) => void,
): JSX.Element {
  return (
    <tr>
      <td className="label">{title}</td>
      <td className="value">{stat?.value}</td>
      <td className="value">{stat ? numberToOrdinalString(stat.placeOverall) : ""}</td>
      <td className="value">
        {stat ? (
          <a href="#" onClick={() => onNavigateToPuzzleByDate(stat.date)}>
            {getDateString(stat.date)}
          </a>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
}

import React from "react";
import "../reviewPuzzleScreen.css";
import { IPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IPuzzleV1";
import { PuzzleWord } from "./puzzleWord";
import { IWordInPuzzleV1 } from "../../dataLayer/v1/interfaces/classes/IWordInPuzzleV1";

export interface IReviewAllWordsProps {
  puzzle: IPuzzleV1;
  selectedWord: string | undefined;
  setSelectedWord: (word: string | undefined) => void;
  wordsToDisplayByLength: IWordInPuzzleV1[][];
}

export function ReviewAllWords(props: IReviewAllWordsProps) {
  return (
    <>
      <div className="majorSectionHeader">All Words</div>
      <div className="majorSectionSubHeader">
        {props.puzzle.isToday ? (
          ""
        ) : (
          <>
            Words you found are <b>bold</b> (times found by community)
          </>
        )}
      </div>
      <div className="majorSectionContainer">
        {props.wordsToDisplayByLength.map((list: IWordInPuzzleV1[], index: number) => {
          if (list && list.length > 0) {
            return (
              <div key={`wordsOfLength${index}`}>
                <div className="regularHeader">{index} Letters</div>
                <div className="sectionContainer">
                  {list.map((wordInPuzzle: IWordInPuzzleV1) => {
                    return (
                      <PuzzleWord
                        key={`regularWord_${wordInPuzzle.word}`}
                        word={wordInPuzzle.word}
                        isFound={!props.puzzle.isToday ? wordInPuzzle.isFound : undefined}
                        isSelected={wordInPuzzle.word === props.selectedWord}
                        onClick={() => {
                          if (wordInPuzzle.word === props.selectedWord) {
                            props.setSelectedWord(undefined);
                          } else {
                            props.setSelectedWord(wordInPuzzle.word);
                          }
                        }}
                        parenText={
                          props.puzzle.isToday ? undefined : wordInPuzzle.timesFound?.toString()
                        }
                      ></PuzzleWord>
                    );
                  })}
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
}
